export default {
  isAuthenticated(state) {
    return state.accessToken ? true : false;
  },
  accessType(state) {
    return state.accessType;
  },
  accessToken(state) {
    return state.accessToken;
  },
  user(state) {
    return state.user;
  },
  role(state) {
    return state.role;
  },
  userName(state) {
    return state.user ? state.user.name : "";
  },
  thumbnail(state) {
    return state.user?.customer ? state.user.customer.logo?.thumbnail : "";
  },
};
