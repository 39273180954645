export default {
  setLoading({ state, commit }, payload) {
    commit("setLoading", payload);
  },
  setLayout({ state, commit }, payload) {
    commit("setLayout", payload);
  },
  toggleSideBar({ state, commit }, payload) {
    commit("toggleSideBar", payload);
  },
  toggleDarkMode({ state, commit }, payload) {
    commit("toggleDarkMode", payload);
  },
  toggleMenuStyle({ state, commit }, payload) {
    commit("toggleMenuStyle", payload);
  },
  toggleLayoutStyle({ state, commit }, payload) {
    commit("toggleLayoutStyle", payload);
  },
  toggleLocale({ state, commit }, payload) {
    commit("toggleLocale", payload);
  },
};
