<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-revenue">
        <div class="widget-heading">
          <b-skeleton type="button"></b-skeleton>
        </div>
        <div class="widget-content">
          <b-skeleton-img height="325px" no-aspect></b-skeleton-img>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget">
        <div class="widget-heading">
          <b-skeleton type="button"></b-skeleton>
        </div>
        <div class="widget-content">
          <b-skeleton-img height="325px" no-aspect></b-skeleton-img>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div
        class="row widget-statistic row-cols-1"
        :class="{
          'row-cols-md-3 row-cols-lg-5': isAdmin,
          'row-cols-md-4 row-cols-lg-4': !isAdmin,
        }"
      >
        <div class="col layout-spacing" v-isAdmin>
          <div class="widget">
            <div class="widget-heading">
              <div class="w-title">
                <div class="w-icon icon-fill-secondary">
                  <b-skeleton type="avatar"></b-skeleton>
                </div>
                <div class="col-12">
                  <b-skeleton class="w-50"></b-skeleton>
                  <h5><b-skeleton class="w-50"></b-skeleton></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col layout-spacing">
          <div class="widget">
            <div class="widget-heading">
              <div class="w-title">
                <div class="w-icon icon-fill-info">
                  <b-skeleton type="avatar"></b-skeleton>
                </div>
                <div class="col-12">
                  <b-skeleton class="w-50"></b-skeleton>
                  <h5><b-skeleton class="w-50"></b-skeleton></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col layout-spacing">
          <div class="widget">
            <div class="widget-heading">
              <div class="w-title">
                <div class="w-icon icon-fill-success">
                  <b-skeleton type="avatar"></b-skeleton>
                </div>
                <div class="col-12">
                  <b-skeleton class="w-50"></b-skeleton>
                  <h5><b-skeleton class="w-50"></b-skeleton></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col layout-spacing">
          <div class="widget">
            <div class="widget-heading">
              <div class="w-title">
                <div class="w-icon icon-fill-warning">
                  <b-skeleton type="avatar"></b-skeleton>
                </div>
                <div class="col-12">
                  <b-skeleton class="w-50"></b-skeleton>
                  <h5><b-skeleton class="w-50"></b-skeleton></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col layout-spacing">
          <div class="widget">
            <div class="widget-heading">
              <div class="w-title">
                <div class="w-icon icon-fill-danger">
                  <b-skeleton type="avatar"></b-skeleton>
                </div>
                <div class="col-12">
                  <b-skeleton class="w-50"></b-skeleton>
                  <h5><b-skeleton class="w-50"></b-skeleton></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home-Skeleton",
  computed: {
    isAdmin() {
      return this.$store.getters["auth/role"] == "admin";
    },
  },
};
</script>

<style>
</style>