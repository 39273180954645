<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'customers.index',
                    }"
                    >Customers</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Edit</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <div class="account-settings-container layout-top-spacing">
      <div class="account-content">
        <div
          class="scrollspy-example"
          data-spy="scroll"
          data-target="#account-settings-scroll"
          data-offset="-100"
        >
          <b-skeleton-wrapper :loading="formLoading && resetLoading">
            <template #loading>
              <EditSkeleton />
            </template>

            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <b-form id="general-info" class="section general-info">
                  <div class="info">
                    <h6 class="">General Information</h6>
                    <div class="row">
                      <div class="col-lg-11 mx-auto">
                        <div class="row">
                          <div class="col-xl-2 col-lg-12 col-md-4">
                            <div class="upload mt-4 pr-md-4">
                              <input
                                ref="fl_profile"
                                type="file"
                                class="d-none"
                                accept="image/*"
                                @change="changeFile"
                              />
                              <img
                                :src="selectedFile ? selectedFile : logo"
                                alt="profile"
                                class="profile-preview border border-1"
                                @click="$refs.fl_profile.click()"
                              />
                              <p class="mt-2">Upload Logo</p>
                              <b-list-group class="list-unstyled" :flush="true">
                                <b-list-group-item
                                  class="text-danger p-0 border-0"
                                  v-for="error in errors.logo"
                                  :key="error"
                                >
                                  <small class="invalid-feedback d-block">{{
                                    error
                                  }}</small>
                                </b-list-group-item>
                              </b-list-group>
                            </div>
                          </div>
                          <div
                            class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4"
                          >
                            <div class="form">
                              <div class="row">
                                <div class="col-sm-6">
                                  <b-form-group
                                    label="Company Name"
                                    description="Enter the company name"
                                    label-for="input-company-name"
                                    :invalid-feedback="invalidCompanyName"
                                    :state="validateState('company_name')"
                                  >
                                    <b-form-input
                                      id="input-company-name"
                                      v-model="form.company_name"
                                      :state="validateState('company_name')"
                                      trim
                                      autocomplete="off"
                                      ref="company_name"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.company_name"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                                <div class="col-sm-6">
                                  <b-form-group
                                    label="Email"
                                    label-for="input-email"
                                  >
                                    <b-form-input
                                      id="input-email"
                                      :value="form.email"
                                      type="email"
                                      :disabled="true"
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6">
                                  <b-form-group>
                                    <label for="country">Country</label>
                                    <multiselect
                                      id="country"
                                      v-model="selectedCountry"
                                      :options="countries"
                                      :searchable="true"
                                      :preselect-first="false"
                                      @select="changeCountry"
                                      label="text"
                                      track-by="text"
                                    ></multiselect>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6">
                                  <b-form-group
                                    :invalid-feedback="invalidState"
                                    :state="validateState('state_id')"
                                  >
                                    <label for="state">State</label>
                                    <multiselect
                                      id="state"
                                      v-model="selectedState"
                                      :options="states"
                                      :searchable="true"
                                      :preselect-first="false"
                                      @select="changeState"
                                      label="text"
                                      track-by="text"
                                      ref="state_id"
                                    ></multiselect>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6">
                                  <b-form-group
                                    :invalid-feedback="invalidCity"
                                    :state="validateState('city_id')"
                                  >
                                    <label for="city">City</label>
                                    <multiselect
                                      id="city"
                                      v-model="selectedCity"
                                      :options="cities"
                                      :searchable="true"
                                      :preselect-first="false"
                                      label="text"
                                      track-by="text"
                                      @select="changeCity"
                                      ref="city_id"
                                    ></multiselect>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.city_id"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                                <div class="col-sm-3">
                                  <b-form-group
                                    label="Backups"
                                    description="Enter the maximum backups"
                                    label-for="input-backups"
                                    :invalid-feedback="invalidBackups"
                                    :state="validateState('max_backups')"
                                  >
                                    <b-form-input
                                      id="input-backups"
                                      v-model="form.max_backups"
                                      :state="validateState('max_backups')"
                                      autocomplete="off"
                                      trim
                                      ref="max_backups"
                                      type="number"
                                      min="1"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.max_backups"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                                <div class="col-sm-3">
                                  <b-form-group
                                    label="Logins"
                                    description="Enter the maximum logins"
                                    label-for="input-logins"
                                    :invalid-feedback="invalidLogins"
                                    :state="
                                      validateState('max_concurrent_logins')
                                    "
                                  >
                                    <b-form-input
                                      id="input-logins"
                                      v-model="form.max_concurrent_logins"
                                      :state="
                                        validateState('max_concurrent_logins')
                                      "
                                      autocomplete="off"
                                      trim
                                      ref="max_concurrent_logins"
                                      type="number"
                                      min="1"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.max_concurrent_logins"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>

              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <b-form id="security" class="section contact">
                  <div class="info">
                    <h5 class="">Account Access</h5>
                    <div class="row">
                      <div class="col-md-11 mx-auto">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              label="Password"
                              description="Enter the password for the account"
                              label-for="input-password"
                              :invalid-feedback="invalidPassword"
                              :state="validateState('password')"
                            >
                              <b-form-input
                                id="input-password"
                                v-model="form.password"
                                :state="validateState('password')"
                                type="password"
                                autocomplete="off"
                                trim
                                ref="password"
                              ></b-form-input>
                            </b-form-group>
                            <b-list-group class="list-unstyled" :flush="true">
                              <b-list-group-item
                                class="text-danger p-0 border-0"
                                v-for="error in errors.password"
                                :key="error"
                              >
                                <small class="invalid-feedback d-block">{{
                                  error
                                }}</small>
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              label="Confirm Password"
                              description="Confirm the password for the account"
                              label-for="input-c-password"
                              :invalid-feedback="invalidConfirmationPassword"
                              :state="validateState('password_confirmation')"
                            >
                              <b-form-input
                                id="input-c-password"
                                v-model="form.password_confirmation"
                                :state="validateState('password_confirmation')"
                                type="password"
                                trim
                                ref="password_confirmation"
                              ></b-form-input>
                            </b-form-group>
                            <b-list-group class="list-unstyled" :flush="true">
                              <b-list-group-item
                                class="text-danger p-0 border-0"
                                v-for="error in errors.password_confirmation"
                                :key="error"
                              >
                                <small class="invalid-feedback d-block">{{
                                  error
                                }}</small>
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>

              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <b-form id="contact" class="section contact">
                  <div class="info">
                    <h5 class="">Contact</h5>
                    <div class="row">
                      <div class="col-md-11 mx-auto">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              label="Contact Person"
                              description="Enter name of the person to contact"
                              label-for="contact_person"
                            >
                              <b-form-input
                                id="contact_person"
                                v-model="form.contact_person"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              label="Phone 1"
                              description="Enter contact number 1"
                              label-for="phone_1"
                            >
                              <b-form-input
                                id="phone_1"
                                v-model="form.phone_number_1"
                                v-mask="'## ##########'"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              label="Phone 2"
                              description="Enter contact number 2"
                              label-for="phone_2"
                            >
                              <b-form-input
                                id="phone_2"
                                v-model="form.phone_number_2"
                                v-mask="'## ##########'"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              label="Sub Domain"
                              description="Enter sub domain address"
                              label-for="subdomain"
                            >
                              <b-form-input
                                id="subdomain"
                                v-model="form.subdomain"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>

      <div class="account-settings-footer">
        <div class="as-footer-container">
          <b-button
            variant="primary"
            @click="resetAll"
            :disabled="resetLoading || formLoading"
          >
            <b-spinner variant="light" small v-if="resetLoading"></b-spinner>
            <span v-else>Reset All</span>
          </b-button>
          <b-button
            :disabled="resetLoading || formLoading"
            variant="success"
            @click="handleSubmit"
          >
            <b-spinner variant="light" small v-if="formLoading"></b-spinner>
            <span v-else>Save Changes</span>
          </b-button>
          <b-toast
            id="error-message"
            header-class="d-none"
            body-class="toast-danger d-flex justify-content-between"
            toaster="b-toaster-top-right"
            auto-hide-delay="200"
          >
            {{ errorMessage }}
          </b-toast>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import Vue from "vue";
import VueMask from "v-mask";
Vue.use(VueMask);
import "@/assets/sass/scrollspyNav.scss";
import "@/assets/sass/users/account-setting.scss";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  required,
  requiredIf,
  minLength,
  sameAs,
  minValue,
} from "vuelidate/lib/validators";
import EditSkeleton from "./EditSkeleton.vue";

export default {
  name: "Customer-Edit",
  metaInfo: { title: "Edit Customer" },
  components: {
    Multiselect,
    EditSkeleton,
  },
  data() {
    return {
      form: {
        company_name: "",
        email: "",
        state_id: "",
        city_id: "",
        password: "",
        password_confirmation: "",
        contact_person: "",
        phone_number_1: "",
        phone_number_2: "",
        subdomain: "",
        max_backups: 1,
        max_concurrent_logins: 1,
        logo: {},
      },
      logo: "",
      selectedCountry: {},
      selectedState: {},
      selectedCity: {},
      selectedFile: "",
      errors: {},
      countries: [],
      states: [],
      cities: [],
      submitted: false,
      formLoading: false,
      resetLoading: false,
      errorMessage: "",
    };
  },
  validations: {
    form: {
      company_name: { required },
      state_id: {
        required: requiredIf(function (form) {
          return Object.keys(this.selectedCountry).length !== 0;
        }),
      },
      city_id: {
        required: requiredIf(function (form) {
          return (
            Object.keys(this.selectedCountry).length !== 0 &&
            Object.keys(this.selectedState).length !== 0
          );
        }),
      },
      password: { minLength: minLength(8) },
      password_confirmation: {
        required: requiredIf(function (form) {
          return form.password !== "";
        }),
        sameAsPassword: sameAs("password"),
      },
      contact_person: {},
      phone_number_1: {},
      phone_number_2: {},
      subdomain: {},
      max_backups: { minValue: minValue(1) },
      max_concurrent_logins: { minValue: minValue(1) },
    },
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetAll() {
      this.getCustomer();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeCountry(e) {
      this.getStates(e.value);
    },
    changeState(e) {
      this.form.state_id = e.value;
      this.getCities(e.value);
    },
    changeCity(e) {
      this.form.city_id = e.value;
    },
    getCustomer() {
      this.formLoading = this.resetLoading = true;
      let form = this.form;
      this.$axios
        .get(`/customer/${this.$route.params.slug}`)
        .then((response) => {
          const { data } = response.data;
          form.company_name = data.company_name;
          form.email = data.email;
          form.contact_person = data.contact_person;
          form.phone_number_1 = data.phone_number_1;
          form.phone_number_2 = data.phone_number_2;
          form.subdomain = data.subdomain;
          form.max_backups = data.max_backups;
          form.max_concurrent_logins = data.max_concurrent_logins;
          this.logo = data.logo
            ? data.logo.url
            : require("@/assets/images/user-avtar.svg");
          this.getCountries().then(() => {
            if (data.country) {
              this.selectedCountry = this.countries.find(
                (country) => country.value == data.country.country_id
              );
              this.getStates(data.country.country_id).then(() => {
                if (data.state) {
                  this.selectedState = this.states.find(
                    (state) => state.value == data.state.state_id
                  );
                  this.getCities(data.state.state_id).then(() => {
                    if (data.city) {
                      this.selectedCity = this.cities.find(
                        (city) => city.value == data.city.city_id
                      );
                      form.state_id = data.state?.state_id;
                      form.city_id = data.city?.city_id;
                    }
                    this.formLoading = this.resetLoading = false;
                  });
                } else {
                  this.formLoading = this.resetLoading = false;
                }
              });
            } else {
              this.formLoading = this.resetLoading = false;
            }
          });
        })
        .catch(({ response }) => {
          this.formLoading = this.resetLoading = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            this.errorMessage = response.data.message;
            this.$bvToast.show("error-message");
          }
        });
    },
    getCountries() {
      this.formLoading = true;
      return this.$axios
        .get("countries")
        .then((response) => {
          this.formLoading = false;
          const { data } = response.data;
          this.countries = data.map((country) => ({
            value: country.country_id,
            text: country.name,
          }));
        })
        .catch(({ response }) => {
          this.formLoading = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            this.errorMessage = response.data.message;
            this.$bvToast.show("error-message");
          }
        });
    },
    getStates(country_id = "") {
      this.formLoading = true;
      let country = country_id
        ? country_id
        : this.selectedCountry
        ? this.selectedCountry.value
        : "";
      if (country) {
        return this.$axios
          .get(`countries/${country}}/states`)
          .then((response) => {
            this.formLoading = false;
            const { data } = response.data;
            this.states = data.map((state) => ({
              value: state.state_id,
              text: state.name,
            }));
            this.cities = [];
            this.selectedState = {};
            this.selectedCity = {};
            this.form.city_id = "";
            this.form.state_id = "";
          })
          .catch(({ response }) => {
            this.formLoading = false;
            let errors = [401, 403];
            if (!errors.includes(response.status)) {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          });
      }
    },
    getCities(state_id = "") {
      this.formLoading = true;
      let state = state_id
        ? state_id
        : this.selectedState
        ? this.selectedState.value
        : "";
      if (state) {
        return this.$axios
          .get(`states/${state}}/cities`)
          .then((response) => {
            this.formLoading = false;
            const { data } = response.data;
            this.cities = data.map((city) => ({
              value: city.city_id,
              text: city.name,
            }));
            this.form.city_id = "";
            this.selectedCity = {};
          })
          .catch(({ response }) => {
            this.formLoading = false;
            let errors = [401, 403];
            if (!errors.includes(response.status)) {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          });
      }
    },
    changeFile(event) {
      this.form.logo = event.target.files[0];
      this.selectedFile = URL.createObjectURL(event.target.files[0]);
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.updateCustomer();
      } else {
        this.focusOnError();
      }
    },
    focusOnError() {
      // 1. Loop the keys
      for (let key in Object.keys(this.$v.form)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.form)[key];
        // // 3. Remove special properties
        if (input.includes("$")) return false;
        // // 4. Check for errors
        if (this.$v.form[input].$error) {
          // 5. Focus the input with the error
          if (this.$refs[input]?.$el) {
            this.$refs[input].$el.focus();
          } else {
            this.$refs[input].focus();
          }
          // 6. Break out of the loop
          break;
        }
      }
    },
    updateCustomer() {
      this.errors = {};
      this.formLoading = true;
      const getFormData = Object.entries(this.form).reduce((fd, [key, val]) => {
        if (Array.isArray(val)) {
          val.forEach((v) => fd.append(key, v ? v : ""));
        } else {
          fd.append(key, val ? val : "");
        }
        return fd;
      }, new FormData());
      getFormData.append("_method", "PATCH");
      if (this.selectedFile == "") {
        getFormData.delete("logo");
      }

      this.$axios
        .post(`/customer/${this.$route.params.slug}`, getFormData)
        .then((response) => {
          this.formLoading = false;
          this.submitted = true;
          const toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            padding: "1em",
          });
          toast.fire({
            icon: "success",
            title: response.data.message,
            padding: "1em",
          });
          this.$router.push({ name: "customers.index" });
        })
        .catch(({ response }) => {
          this.formLoading = false;
          this.submitted = true;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            if (response.data.errors) {
              this.errors = response.data.errors;
            } else {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          }
        });
    },
  },
  computed: {
    invalidCompanyName() {
      let message = "";
      if (!this.$v.form.company_name.required) {
        message = "Company name is required!";
      }
      return message;
    },
    invalidPassword() {
      let message = "";
      if (!this.$v.form.password.required) {
        message = "Password is required!";
      } else if (!this.$v.form.password.minLength) {
        message = "Password min length is 8!";
      }
      return message;
    },
    invalidConfirmationPassword() {
      let message = "";
      if (!this.$v.form.password_confirmation.required) {
        message = "Confirmation password is required!";
      } else if (!this.$v.form.password_confirmation.sameAsPassword) {
        message = "Confirmation password doesn't match!";
      }
      return message;
    },
    invalidCity() {
      let message = "";
      if (!this.$v.form.city_id.required) {
        message = "City is required!";
      }
      return message;
    },
    invalidState() {
      let message = "";
      if (
        Object.keys(this.selectedCountry).length !== 0 &&
        Object.keys(this.selectedState).length == 0
      ) {
        message = "State is required!";
      }
      return message;
    },
    invalidBackups() {
      let message = "";
      if (!this.$v.form.max_backups.minValue) {
        message = "Should have minimum 1!";
      }
      return message;
    },
    invalidLogins() {
      let message = "";
      if (!this.$v.form.max_concurrent_logins.minValue) {
        message = "Should have minimum 1!";
      }
      return message;
    },
  },
};
</script>
    