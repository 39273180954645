require("./axios");
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//bootstrap vue
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

//perfect scrollbar
import PerfectScrollbar from "vue2-perfect-scrollbar";
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

//Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

//Sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "#50575d",
  cancelButtonColor: "#e7515a",
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from "portal-vue";
Vue.use(PortalVue);

//vue-i18n
import i18n from "./i18n";

Vue.config.productionTip = false;

// set default settings
import appSetting from "./app-setting";
Vue.prototype.$appSetting = appSetting;
appSetting.init();

Vue.directive("isAdmin", {
  inserted: function (el, binding, vnode) {
    let hasPermission = vnode.context.$store.getters["auth/role"] == "admin";
    if (!hasPermission) {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.directive("isCustomer", {
  inserted: function (el, binding, vnode) {
    let hasPermission = vnode.context.$store.getters["auth/role"] == "customer";
    if (!hasPermission) {
      el.parentNode.removeChild(el);
    }
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
