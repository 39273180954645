<template>
  <div class="row layout-spacing">
    <!-- Content -->
    <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div class="user-profile layout-spacing">
        <div class="panel">
          <div class="panel-body">
            <div class="text-center user-info">
              <b-skeleton-img
                class="m-auto"
                height="90px"
                width="90px"
                :no-aspect="true"
              ></b-skeleton-img>
              <p class="mt-2"><b-skeleton></b-skeleton></p>
            </div>
            <div class="user-info-list">
              <div class="">
                <ul class="contacts-block list-unstyled">
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="geo-alt-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="envelope-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="person-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="telephone-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="telephone-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                  <li class="contacts-block__item">
                    <span class="d-inline-flex w-100">
                      <b-skeleton-icon
                        icon="link45deg-fill"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon
                      ><b-skeleton class="w-100"></b-skeleton>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
      <div class="skills layout-spacing">
        <div class="panel">
          <div class="panel-body">
            <h3 class=""><b-skeleton type="button"></b-skeleton></h3>
            <div class="custom-table">
              <div class="table-header">
                <div class="d-flex align-items-center">
                  <span>
                    <b-skeleton type="button"></b-skeleton>
                  </span>
                </div>
                <div class="header-search w-25">
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>

              <b-skeleton-table
                :rows="5"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>

              <div class="table-footer">
                <div class="dataTables_info">
                  <b-skeleton type="input"></b-skeleton>
                </div>
                <div
                  class="
                    paginating-container
                    pagination-solid
                    flex-column
                    align-items-right
                  "
                >
                  <b-pagination
                    prev-text="Prev"
                    next-text="Next"
                    first-text="First"
                    last-text="Last"
                    first-class="first"
                    prev-class="prev"
                    next-class="next"
                    last-class="last"
                    class="rounded"
                  >
                    <template #first-text>
                      <b-skeleton-icon
                        icon="chevron-double-left"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #prev-text>
                      <b-skeleton-icon
                        icon="chevron-left"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #next-text>
                      <b-skeleton-icon
                        icon="chevron-right"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #last-text>
                      <b-skeleton-icon
                        icon="chevron-double-right"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 layout-top-spacing">
      <div class="skills layout-spacing">
        <div class="panel">
          <div class="panel-body">
            <h3 class=""><b-skeleton type="button"></b-skeleton></h3>
            <div class="custom-table">
              <div class="table-header">
                <div class="d-flex align-items-center">
                  <span>
                    <b-skeleton type="button"></b-skeleton>
                  </span>
                </div>
                <div class="header-search w-25">
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </div>

              <b-skeleton-table
                :rows="5"
                :columns="7"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>

              <div class="table-footer">
                <div class="dataTables_info">
                  <b-skeleton type="input"></b-skeleton>
                </div>
                <div
                  class="
                    paginating-container
                    pagination-solid
                    flex-column
                    align-items-right
                  "
                >
                  <b-pagination
                    prev-text="Prev"
                    next-text="Next"
                    first-text="First"
                    last-text="Last"
                    first-class="first"
                    prev-class="prev"
                    next-class="next"
                    last-class="last"
                    class="rounded"
                  >
                    <template #first-text>
                      <b-skeleton-icon
                        icon="chevron-double-left"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #prev-text>
                      <b-skeleton-icon
                        icon="chevron-left"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #next-text>
                      <b-skeleton-icon
                        icon="chevron-right"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                    <template #last-text>
                      <b-skeleton-icon
                        icon="chevron-double-right"
                        :icon-props="{ variant: 'dark' }"
                      ></b-skeleton-icon>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "Customer-Details-Skeleton",
};
</script>