<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'backups.index',
                    }"
                    >Backups</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>List</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div class="panel br-6 p-0">
          <div class="custom-table">
            <div class="table-header">
              
              <div class="header-search">
                <b-input
                  v-model="searchText"
                  size="sm"
                  placeholder="Search..."
                />
                <div class="search-image" v-html="getSvg('search')"></div>
              </div>

              <div class="d-flex align-items-center">
                <span>Results per page :</span>
                <span class="ml-2">
                  <b-select v-model="perPage" size="sm">
                    <b-select-option value="5">5</b-select-option>
                    <b-select-option value="10">10</b-select-option>
                    <b-select-option value="20">20</b-select-option>
                    <b-select-option value="50">50</b-select-option>
                  </b-select>
                </span>
              </div>
            </div>

            <b-table
              ref="basic_table"
              responsive
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :items="getTableItems"
              :fields="columns"
              :filter="searchText"
              :show-empty="true"
              :busy="isBusy"
              :sort-desc="true"
            >
              <template #table-busy>
                <TableSkeleton :columns="6" />
              </template>
              <template #cell(action)="row">

                <b-button
                  size="sm"
                  variant="primary"
                  :href="row.item.download_link"
                  v-b-tooltip
                  title="Download backup"
                  class="mr-1">
                  <span v-html="getSvg('download')"></span>
                </b-button>

                <!-- <b-button
                  size="sm"
                  variant="primary"
                  :href="row.item.download_link"
                  >Download</b-button
                > -->
              </template>
            </b-table>

            <div class="table-footer">
              <div class="dataTables_info">
                Showing {{ totalRows ? meta.start_index + 1 : 0 }} to
                {{ totalRows ? meta.end_index + 1 : 0 }} of {{ totalRows }}
              </div>
              <div
                class="
                  paginating-container
                  pagination-solid
                  flex-column
                  align-items-right
                "
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  prev-text="Prev"
                  next-text="Next"
                  first-text="First"
                  last-text="Last"
                  first-class="first"
                  prev-class="prev"
                  next-class="next"
                  last-class="last"
                >
                  <template #first-text>
                    <div v-html="getSvg('chevrons-left')"></div>
                  </template>
                  <template #prev-text>
                    <div v-html="getSvg('chevron-left')"></div>
                  </template>
                  <template #next-text>
                    <div v-html="getSvg('chevron-right')"></div>
                  </template>
                  <template #last-text>
                    <div v-html="getSvg('chevrons-right')"></div>
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import feather from "feather-icons";
import TableSkeleton from "@/components/TableSkeleton.vue";
export default {
  name: "Backups",
  metaInfo: { title: "Backups List" },
  components: { TableSkeleton },
  data() {
    return {
      items: [],
      columns: [],
      meta: {},
      isBusy: false,
      perPage: 5,
      currentPage: 1,
      searchText: "",
      totalRows: 0,
    };
  },
  mounted() {
    feather.replace();
    this.columns = [
      { key: "backup_id", label: "ID", sortable: true },
      {
        key: "customer",
        label: "Customer",
        class: this.isAdmin ? "" : "d-none",
      },
      { key: "name", label: "Name", sortable: true },
      { key: "ip", label: "IP" },
      { key: "created_on", label: "Created On", sortable: true },
      { key: "action", label: "Actions", class: "actions text-center" },
    ];
  },
  methods: {
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    pageChanged(page) {
      this.currentPage = page;
    },
    getTableItems(ctx) {
      let params = {
        page: ctx.currentPage,
        search: ctx.filter,
        per_page: ctx.perPage,
        sort_by: ctx.sortBy,
        sort_desc: ctx.sortDesc ? 1 : 0,
      };
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true

      let promise = this.$axios.get("customer-backup", { params });

      return promise
        .then((response) => {
          const results = response.data;
          this.totalRows = results.meta.total;
          this.items = results.data.map((entry, index) => ({
            backup_id: entry.backup_id,
            customer: entry.customer.name,
            name: entry.name,
            ip: entry.ip,
            created_on: entry.created_on,
            download_link: entry.download_link,
          }));
          this.getMeta();
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false
          return this.items;
        })
        .catch(({ response }) => {
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
          return [];
        });
    },
    getMeta() {
      let startIndex = (this.currentPage - 1) * this.perPage;
      let endIndex = Math.min(
        startIndex + this.perPage - 1,
        this.totalRows - 1
      );
      this.meta = {
        start_index: startIndex,
        end_index: endIndex,
      };
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/role"] == "admin";
    },
  },
};
</script>
  