<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header></Header>
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      class="main-container"
      id="container"
      :class="[
        !isShowSidebar ? 'sidebar-closed sbar-open' : '',
        menuStyle === 'collapsible-vertical'
          ? 'collapsible-vertical-mobile'
          : '',
      ]"
    >
      <!--  BEGIN OVERLAY  -->
      <div
        class="overlay"
        :class="{ show: !isShowSidebar }"
        @click="toggleSideBar"
      ></div>
      <div
        class="search-overlay"
        :class="{ show: isShowSearch }"
        @click="toggleSearch"
      ></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <router-view>
          <!-- BEGIN LOADER -->
          <div id="load_screen">
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center"></div>
              </div>
            </div>
          </div>
          <!--  END LOADER -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <!-- <Footer></Footer> -->
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/header.vue";
import Sidebar from "@/components/layout/sidebar.vue";
// import Footer from "@/components/layout/footer.vue";
// import appSettings from "@/components/app-settings.vue";
export default {
  components: {
    Header,
    Sidebar,
    // Footer,
    // appSettings,
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar", !this.isShowSidebar);
    },
    toggleSearch() {
      this.$store.dispatch("app/toggleSearch", !this.isShowSearch);
    },
  },
  computed: {
    isShowSidebar() {
      return this.$store.getters["app/isShowSidebar"];
    },
    menuStyle() {
      return this.$store.getters["app/menuStyle"];
    },
    isShowSearch() {
      return this.$store.getters["app/isShowSearch"];
    },
  },
};
</script>
