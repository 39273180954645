<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'customers.index',
                    }"
                    >Customers</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>List</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <b-col class="p-0 text-right mb-2" v-isAdmin> </b-col>
        <div class="panel br-6 p-0">
          <div class="custom-table">
            <div class="table-header">
              <div class="header-search">
                <b-input
                  v-model="searchText"
                  size="sm"
                  placeholder="Search..."
                />
                <div class="search-image" v-html="getSvg('search')"></div>
              </div>

              <b-button
                :to="{ name: 'customers.create' }"
                variant="dark"
                class="mb-2 mr-1"
              >
                <i data-feather="users"></i>
                <span class="ml-2">New Customer</span>
              </b-button>
            </div>

            <b-table
              ref="basic_table"
              responsive
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :items="getTableItems"
              :fields="columns"
              :filter="searchText"
              :show-empty="true"
              :busy="isBusy"
              :sort-desc="true"
            >
              <template #table-busy>
                <TableSkeleton :columns="8" />
              </template>
              <template #cell(action)="row">
                <div class="d-flex">
                  <b-button
                    size="sm"
                    variant="primary"
                    v-b-tooltip
                    title="View Details"
                    class="mr-1"
                    :to="{
                      name: 'customers.show',
                      params: { slug: row.item.slug },
                    }"
                  >
                    <span v-html="getSvg('eye')"></span>
                  </b-button>

                  <b-button
                    size="sm"
                    variant="primary"
                    class="mr-1"
                    v-b-tooltip
                    title="Edit Customer"
                    :to="{
                      name: 'customers.edit',
                      params: { slug: row.item.slug },
                    }"
                  >
                    <span v-html="getSvg('edit-2')"></span>
                  </b-button>

                  <b-button
                    size="sm"
                    variant="primary"
                    v-b-tooltip
                    title="Delete Customer"
                    class="mr-1"
                    @click="deleteRow(row.item)"
                  >
                    <span v-html="getSvg('trash')"></span>
                  </b-button>
                </div>
                <!-- <a
                <router-link
                  class="mr-1"
                  title="View"
                  v-html="getSvg('eye')"
                  v-b-tooltip
                  :to="{
                    name: 'customers.show',
                    params: { slug: row.item.slug },
                  }"
                ></router-link>
                <router-link
                  class="mr-1"
                  title="Edit"
                  v-html="getSvg('edit-2')"
                  v-b-tooltip
                  :to="{
                    name: 'customers.edit',
                    params: { slug: row.item.slug },
                  }"
                ></router-link>
                <a
                  href="javascript:void(0);"
                  v-b-tooltip
                  title="Delete"
                  @click="deleteRow(row.item)"
                  v-html="getSvg('trash')"
                >
                </a> -->
              </template>
            </b-table>

            <div class="table-footer">
              <div class="d-flex">
                <div class="d-flex align-items-center mr-4">
                  <span>No of Results per page :</span>
                  <span class="ml-2">
                    <b-select v-model="perPage" size="sm">
                      <b-select-option value="5">5</b-select-option>
                      <b-select-option value="10">10</b-select-option>
                      <b-select-option value="20">20</b-select-option>
                      <b-select-option value="50">50</b-select-option>
                    </b-select>
                  </span>
                </div>

                <div class="dataTables_info">
                  Showing {{ totalRows ? meta.start_index + 1 : 0 }} to
                  {{ totalRows ? meta.end_index + 1 : 0 }} of {{ totalRows }}
                </div>
              </div>

              <div
                class="
                  paginating-container
                  pagination-solid
                  flex-column
                  align-items-right
                "
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  prev-text="Prev"
                  next-text="Next"
                  first-text="First"
                  last-text="Last"
                  first-class="first"
                  prev-class="prev"
                  next-class="next"
                  last-class="last"
                >
                  <template #first-text>
                    <div v-html="getSvg('chevrons-left')"></div>
                  </template>
                  <template #prev-text>
                    <div v-html="getSvg('chevron-left')"></div>
                  </template>
                  <template #next-text>
                    <div v-html="getSvg('chevron-right')"></div>
                  </template>
                  <template #last-text>
                    <div v-html="getSvg('chevrons-right')"></div>
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-toast
      id="error-message"
      header-class="d-none"
      body-class="toast-danger d-flex justify-content-between"
      toaster="b-toaster-top-right"
      auto-hide-delay="200"
    >
      {{ errorMessage }}
    </b-toast>
  </div>
</template>

<script>
import feather from "feather-icons";
import { Fragment } from "vue-fragment";
import TableSkeleton from "@/components/TableSkeleton.vue";

export default {
  name: "Customers",
  metaInfo: { title: "Customers List" },
  components: { Fragment, TableSkeleton },
  data() {
    return {
      items: [],
      columns: [],
      meta: {},
      isBusy: false,
      perPage: 5,
      currentPage: 1,
      searchText: "",
      totalRows: 0,
      errorMessage: "",
    };
  },
  mounted() {
    feather.replace();
    this.columns = [
      { key: "id", label: "#" },
      { key: "company_name", label: "Customer", sortable: true },
      // { key: "address", label: "City, State, Country" },
      { key: "total_licenses", label: "Total of Licenses", sortable: true },
      {
        key: "installations",
        label: "Number of Installations",
        sortable: true,
      },
      {
        key: "backups",
        label: "Allowed Backups",
        sortable: false,
      },
      {
        key: "logins",
        label: "Allowed Logins",
        sortable: false,
      },
      { key: "action", label: "Actions", class: "actions text-center" },
    ];
  },
  methods: {
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    pageChanged(page) {
      this.currentPage = page;
    },
    getTableItems(ctx) {
      let params = {
        page: ctx.currentPage,
        search: ctx.filter,
        per_page: ctx.perPage,
        sort_by: ctx.sortBy,
        sort_desc: ctx.sortDesc ? 1 : 0,
      };
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true;

      let promise = this.$axios.get("customer", { params });

      return promise
        .then((response) => {
          const results = response.data;
          this.totalRows = results.meta.total;
          this.items = results.data.map((entry, index) => ({
            id: ++index,
            company_name: entry.company_name,
            // address: entry.city
            //   ? entry.city.name +
            //     ", " +
            //     entry.state.name +
            //     ", " +
            //     entry.country.name
            //   : "",
            total_licenses: entry.total_licenses,
            installations: entry.installations,
            slug: entry.slug,
            backups: entry.max_backups,
            logins: entry.max_concurrent_logins,
          }));
          this.getMeta();
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false;
          return this.items;
        })
        .catch(({ response }) => {
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
          return [];
        });
    },
    getMeta() {
      let startIndex = (this.currentPage - 1) * this.perPage;
      let endIndex = Math.min(
        startIndex + this.perPage - 1,
        this.totalRows - 1
      );
      this.meta = {
        start_index: startIndex,
        end_index: endIndex,
      };
    },
    deleteRow(customer) {
      console.log(customer);
      this.$swal({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Delete",
        padding: "2em",
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`/customer/${customer.slug}`)
            .then(() => {
              this.$refs.basic_table.refresh();
              this.$swal("Deleted!", "Customer has been deleted.", "success");
            })
            .catch(({ response }) => {
              let errors = [401, 403];
              if (!errors.includes(response.status)) {
                if (response.data.errors) {
                  this.errors = response.data.errors;
                } else {
                  this.errorMessage = response.data.message;
                  this.$bvToast.show("error-message");
                }
              }
            });
        }
      });
    },
  },
};
</script>
