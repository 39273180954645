<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'customers.index',
                    }"
                    >Customers</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Details</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <DetailsSkeleton />
      </template>

      <div class="row layout-spacing">
        <!-- Content -->
        <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
          <div class="user-profile layout-spacing">
            <div class="panel">
              <div class="panel-body">
                <div class="d-flex justify-content-between">
                  <h3 class="">Profile</h3>
                  <router-link
                    :to="{
                      name: 'customers.edit',
                      params: { slug: customerSlug },
                    }"
                    class="mt-2 edit-profile float-right"
                    v-html="getSvg('edit-3')"
                  >
                  </router-link>
                </div>
                <div class="text-center user-info">
                  <img
                    :src="customer.logo.url"
                    alt="avatar"
                    v-if="customer.logo"
                    class="customer-logo"
                  />
                  <b-avatar
                    v-else
                    variant="light"
                    :text="customer.company_name | short_hand"
                    size="5rem"
                    class="border"
                    rounded
                  ></b-avatar>
                  <p class="">{{ customer.company_name }}</p>
                  <p class="">
                    <b-button
                      variant="primary"
                      :disabled="licenseGenerating"
                      v-html="getSvg('plus') + ' Generate New License'"
                      v-b-modal.license-modal
                    ></b-button>
                  </p>
                </div>
                <div class="user-info-list">
                  <div class="">
                    <ul class="contacts-block list-unstyled">
                      <li class="contacts-block__item">
                        <span
                          ><span v-html="getSvg('map-pin')"></span
                          >{{
                            customer.city
                              ? customer.city?.name +
                                ", " +
                                customer.state?.name +
                                ", " +
                                customer.country?.name
                              : ""
                          }}</span
                        >
                      </li>
                      <li class="contacts-block__item">
                        <a :href="'mailto:' + customer.email">
                          <span class="small"
                            ><span v-html="getSvg('mail')"></span
                            >{{ customer.email }}
                          </span>
                        </a>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('user')"></span
                          >{{
                            customer.contact_person
                              ? customer.contact_person
                              : ""
                          }}
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('phone')"></span
                          >{{ customer.phone_number_1 }}
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('phone')"></span
                          >{{ customer.phone_number_2 }}
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('link-2')"></span
                          >{{ customer.subdomain }}
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('save')"></span>Backup Limit:
                          <b>{{ customer.max_backups }}</b>
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('log-in')"></span>Login Limit:
                          <b>{{ customer.max_concurrent_logins }}</b>
                        </span>
                      </li>
                      <li class="contacts-block__item">
                        <span>
                          <span v-html="getSvg('key')"></span
                          ><b-button
                            :pill="true"
                            variant="outline-primary"
                            v-clipboard:copy="customer.api_token"
                            @click="$bvToast.show('clipboard-message')"
                            >Token</b-button
                          >
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
          <div class="skills layout-spacing">
            <div class="panel">
              <div class="panel-body">
                <h3 class="">Backups</h3>
                <div class="custom-table">
                  <div class="table-header">
                    <div class="d-flex align-items-center">
                      <span>Results :</span>
                      <span class="ml-2">
                        <b-select v-model="backups.perPage" size="sm">
                          <b-select-option value="5">5</b-select-option>
                          <b-select-option value="10">10</b-select-option>
                          <b-select-option value="20">20</b-select-option>
                          <b-select-option value="50">50</b-select-option>
                        </b-select>
                      </span>
                    </div>
                    <div class="header-search">
                      <b-input
                        v-model="backups.searchText"
                        size="sm"
                        placeholder="Search..."
                      />
                      <div class="search-image">
                        <i data-feather="search"></i>
                      </div>
                    </div>
                  </div>

                  <b-table
                    ref="basic_table_1"
                    responsive
                    hover
                    sort-by="id"
                    :current-page="backups.currentPage"
                    :per-page="backups.perPage"
                    :items="getBackupTableItems"
                    :fields="backups.columns"
                    :filter="backups.searchText"
                    :show-empty="true"
                    :busy="backups.isBusy"
                  >
                    <template #table-busy>
                      <TableSkeleton />
                    </template>
                    <template #cell(action)="row">
                      <b-button
                        size="sm"
                        variant="primary"
                        :href="row.item.download_link"
                        >Download</b-button
                      >
                    </template>
                  </b-table>

                  <div class="table-footer">
                    <div class="dataTables_info">
                      Showing
                      {{ backups.totalRows ? backups.meta.start_index + 1 : 0 }}
                      to {{ backups.meta.end_index + 1 }} of
                      {{ backups.totalRows }}
                    </div>
                    <div
                      class="
                        paginating-container
                        pagination-solid
                        flex-column
                        align-items-right
                      "
                    >
                      <b-pagination
                        v-model="backups.currentPage"
                        :total-rows="backups.totalRows"
                        :per-page="backups.perPage"
                        prev-text="Prev"
                        next-text="Next"
                        first-text="First"
                        last-text="Last"
                        first-class="first"
                        prev-class="prev"
                        next-class="next"
                        last-class="last"
                        class="rounded"
                      >
                        <template #first-text>
                          <div v-html="getSvg('chevrons-left')"></div>
                        </template>
                        <template #prev-text>
                          <div v-html="getSvg('chevron-left')"></div>
                        </template>
                        <template #next-text>
                          <div v-html="getSvg('chevron-right')"></div>
                        </template>
                        <template #last-text>
                          <div v-html="getSvg('chevrons-right')"></div>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 layout-top-spacing">
          <div class="skills layout-spacing">
            <div class="panel">
              <div class="panel-body">
                <h3 class="">Licenses</h3>
                <div class="custom-table">
                  <div class="table-header">
                    <div class="d-flex align-items-center">
                      <span>Results :</span>
                      <span class="ml-2">
                        <b-select v-model="licenses.perPage" size="sm">
                          <b-select-option value="5">5</b-select-option>
                          <b-select-option value="10">10</b-select-option>
                          <b-select-option value="20">20</b-select-option>
                          <b-select-option value="50">50</b-select-option>
                        </b-select>
                      </span>
                    </div>
                    <div class="header-search">
                      <b-input
                        v-model="licenses.searchText"
                        size="sm"
                        placeholder="Search..."
                      />
                      <div class="search-image">
                        <i data-feather="search"></i>
                      </div>
                    </div>
                  </div>

                  <b-table
                    ref="basic_table_2"
                    responsive
                    hover
                    sort-by="id"
                    :current-page="licenses.currentPage"
                    :per-page="licenses.perPage"
                    :items="getLicenseTableItems"
                    :fields="licenses.columns"
                    :filter="licenses.searchText"
                    :show-empty="true"
                    :busy="licenses.isBusy"
                  >
                    <template #table-busy>
                      <TableSkeleton :columns="6" />
                    </template>
                    <template #cell(code)="row">
                      <div class="">
                        {{ row.item.code }}
                        <b-button
                          size="sm"
                          class="ml-1"
                          variant="outline-primary"
                          v-clipboard:copy="row.item.code"
                          v-html="getSvg('copy')"
                          v-b-tooltip
                          title="Click to Copy"
                          @click="$bvToast.show('clipboard-message')"
                        ></b-button>
                      </div>
                    </template>
                  </b-table>

                  <div class="table-footer">
                    <div class="dataTables_info">
                      Showing
                      {{
                        licenses.totalRows ? licenses.meta.start_index + 1 : 0
                      }}
                      to {{ licenses.meta.end_index + 1 }} of
                      {{ licenses.totalRows }}
                    </div>
                    <div
                      class="
                        paginating-container
                        pagination-solid
                        flex-column
                        align-items-right
                      "
                    >
                      <b-pagination
                        v-model="licenses.currentPage"
                        :total-rows="licenses.totalRows"
                        :per-page="licenses.perPage"
                        prev-text="Prev"
                        next-text="Next"
                        first-text="First"
                        last-text="Last"
                        first-class="first"
                        prev-class="prev"
                        next-class="next"
                        last-class="last"
                        class="rounded"
                      >
                        <template #first-text>
                          <div v-html="getSvg('chevrons-left')"></div>
                        </template>
                        <template #prev-text>
                          <div v-html="getSvg('chevron-left')"></div>
                        </template>
                        <template #next-text>
                          <div v-html="getSvg('chevron-right')"></div>
                        </template>
                        <template #last-text>
                          <div v-html="getSvg('chevrons-right')"></div>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-toast
        id="clipboard-message"
        header-class="d-none"
        body-class="toast-success d-flex justify-content-between"
        toaster="b-toaster-top-right"
        auto-hide-delay="200"
      >
        Copied To Clipboard
      </b-toast>
    </b-skeleton-wrapper>
    <b-modal
      id="license-modal"
      title="Generate License"
      no-stacking
      hide-footer
    >
      <b-form @submit.prevent="handleSubmit">
        <b-form-group
          :invalid-feedback="invalidExpiry"
          :state="validateState('expiry')"
          label="Expiry Date"
          label-for="input-expiry"
          description="Select the expiry date"
          class="mt-2 mb-4"
        >
          <b-form-datepicker
            button-variant="dark"
            close-button-variant="dark"
            nav-button-variant="dark"
            reset-button-variant="dark"
            selected-variant="dark"
            today-button-variant="dark"
            today-variant="dark"
            id="input-expiry"
            v-model="form.expiry"
            ref="expiry"
            :min="minDate"
          ></b-form-datepicker>
          <b-list-group class="list-unstyled" :flush="true">
            <b-list-group-item
              class="text-danger p-0 border-0"
              v-for="error in errors.expiry"
              :key="error"
            >
              <small>{{ error }}</small>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
        <b-form-group class="form-group text-center">
          <b-button
            type="submit"
            :disabled="licenseGenerating"
            variant="success"
            class="w-100"
          >
            <b-spinner
              variant="light"
              small
              v-if="licenseGenerating"
            ></b-spinner>
            <span v-else>Submit</span>
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/sass/scrollspyNav.scss";
import "@/assets/sass/users/user-profile.scss";
import feather from "feather-icons";
import "@/assets/sass/font-icons/fontawesome/css/regular.css";
import "@/assets/sass/font-icons/fontawesome/css/fontawesome.css";
import DetailsSkeleton from "./DetailsSkeleton.vue";
import TableSkeleton from "@/components/TableSkeleton.vue";
import VueClipboard from "vue-clipboard2";
import { required } from "vuelidate/lib/validators";
Vue.use(VueClipboard);

export default {
  name: "Customer-Details",
  metaInfo: { title: "Customer Details" },
  components: {
    DetailsSkeleton,
    TableSkeleton,
  },
  data() {
    return {
      customerSlug: this.$route.params.slug,
      loading: false,
      customer: {},
      licenses: {
        items: [],
        columns: [],
        meta: {},
        isBusy: false,
        perPage: 5,
        currentPage: 1,
        searchText: "",
        totalRows: 0,
      },
      backups: {
        items: [],
        columns: [],
        meta: {},
        isBusy: false,
        perPage: 5,
        currentPage: 1,
        searchText: "",
        totalRows: 0,
      },
      licenseGenerating: false,
      form: {
        expiry: this.getDefaultDate(),
      },
      errors: {},
      minDate: new Date(),
    };
  },
  validations: {
    form: {
      expiry: { required },
    },
  },
  mounted() {
    feather.replace();
    this.getDetails();
    this.backups.columns = [
      { key: "backup_id", label: "ID", sortable: true },
      { key: "name", label: "Name", sortable: true },
      { key: "ip", label: "IP" },
      { key: "created_on", label: "Created On", sortable: true },
      { key: "action", label: "Actions", class: "actions text-center" },
    ];
    this.licenses.columns = [
      { key: "license_id", label: "ID", sortable: true },
      // { key: "ip", label: "IP" },
      { key: "code", label: "Code" },
      { key: "expires_at", label: "Expiry", sortable: true },
      {
        key: "enabled",
        label: "Enabled",
        formatter: (value) => {
          return value ? "Yes" : "No";
        },
      },
      {
        key: "activated",
        label: "Activated",
        formatter: (value) => {
          return value ? "Yes" : "No";
        },
      },
      { key: "activated_on", label: "Activated On", sortable: true },
      // { key: "action", label: "Actions", class: "actions text-center" },
    ];
  },
  methods: {
    getDefaultDate() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date.toISOString().slice(0, 10);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    focusOnError() {
      // 1. Loop the keys
      for (let key in Object.keys(this.$v.form)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.form)[key];
        // // 3. Remove special properties
        if (input.includes("$")) return false;

        // // 4. Check for errors
        if (this.$v.form[input].$error) {
          // 5. Focus the input with the error
          if (this.$refs[input]?.$el) {
            this.$refs[input].$el.focus();
          } else {
            this.$refs[input].focus();
          }

          // 6. Break out of the loop
          break;
        }
      }
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.generateNewLicense();
      } else {
        this.focusOnError();
      }
    },
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    getDetails() {
      this.loading = true;
      this.$axios
        .get(`customer/${this.customerSlug}`)
        .then((response) => {
          const { data } = response.data;
          this.customer = data;
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
        });
    },
    getLicenseTableItems(ctx) {
      let params = {
        page: ctx.currentPage,
        search: ctx.filter,
        per_page: ctx.perPage,
        sort_by: ctx.sortBy,
        sort_desc: ctx.sortDesc ? 1 : 0,
      };
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true
      let promise = this.$axios.get(`customer/${this.customerSlug}/licenses`, {
        params,
      });

      return promise
        .then((response) => {
          const results = response.data;
          this.licenses.totalRows = results.meta.total;
          this.items = results.data.map((entry, index) => ({
            license_id: entry.license_id,
            ip: entry.ip,
            code: entry.code,
            expires_at: entry.expires_at,
            enabled: entry.enabled,
            activated: entry.activated,
            activated_on: entry.activated_on,
          }));
          this.getLicenseMeta();
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false
          return this.items;
        })
        .catch(({ response }) => {
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
          return [];
        });
    },
    getLicenseMeta() {
      let startIndex = (this.licenses.currentPage - 1) * this.licenses.perPage;
      let endIndex = Math.min(
        startIndex + this.licenses.perPage - 1,
        this.licenses.totalRows - 1
      );
      this.licenses.meta = {
        start_index: startIndex,
        end_index: endIndex,
      };
    },
    getBackupTableItems(ctx) {
      let params = {
        page: ctx.currentPage,
        search: ctx.filter,
        per_page: ctx.perPage,
        sort_by: ctx.sortBy,
        sort_desc: ctx.sortDesc ? 1 : 0,
      };
      // Here we don't set isBusy prop, so busy state will be
      // handled by table itself
      // this.isBusy = true
      let promise = this.$axios.get(`customer/${this.customerSlug}/backups`, {
        params,
      });

      return promise
        .then((response) => {
          const results = response.data;
          this.backups.totalRows = results.meta.total;
          this.items = results.data.map((entry, index) => ({
            backup_id: entry.backup_id,
            name: entry.name,
            ip: entry.ip,
            created_on: entry.created_on,
            download_link: entry.download_link,
          }));
          this.getBackupMeta();
          // Here we could override the busy state, setting isBusy to false
          // this.isBusy = false
          return this.items;
        })
        .catch(({ response }) => {
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
          return [];
        });
    },
    getBackupMeta() {
      let startIndex = (this.backups.currentPage - 1) * this.backups.perPage;
      let endIndex = Math.min(
        startIndex + this.backups.perPage - 1,
        this.backups.totalRows - 1
      );
      this.backups.meta = {
        start_index: startIndex,
        end_index: endIndex,
      };
    },
    generateNewLicense() {
      this.licenseGenerating = true;
      this.$axios
        .post(`customer/${this.customerSlug}/license`, {
          expiry: this.form.expiry + " 00:00:00",
        })
        .then((response) => {
          this.licenseGenerating = false;
          const toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            padding: "1em",
          });
          toast.fire({
            icon: "success",
            title: response.data.message,
            padding: "1em",
          });
          this.form.expiry = null;
          this.$root.$emit("bv::hide::modal", `license-modal`);
          this.$refs.basic_table_2.refresh();
        })
        .catch(({ response }) => {
          this.licenseGenerating = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "error",
              title: response.data.message,
              padding: "1em",
            });
          }
        });
    },
  },
  computed: {
    invalidExpiry() {
      let message = "";
      if (!this.$v.form.expiry.required) {
        message = "Expiry date is required!";
      }
      return message;
    },
  },
  filters: {
    short_hand(name) {
      // You can put your logic here...
      if (name) {
        let words = name.split(" ");
        let short_hand = words[0][0] + words[words.length - 1][0];
        return short_hand; // <-- The return value as Per logic
      }
    },
  },
};
</script>


<style scoped>
.customer-logo {
  width: 90px;
  height: 90px;
}
</style>
