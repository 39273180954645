import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Forbidden from "../views/errors/Forbidden.vue";

import Login from "../views/Login";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import Profile from "../views/Profile";
import Home from "../views/Home";
import CustomerList from "../views/customers/List.vue";
import CustomerDetails from "../views/customers/Details.vue";
import CustomerCreate from "../views/customers/Create.vue";
import CustomerEdit from "../views/customers/Edit.vue";
// import LicenseList from "../views/licenses/List.vue";
// import LicenseCreate from "../views/licenses/Create.vue";
import BackupList from "../views/backups/List.vue";

Vue.use(VueRouter);

const isGuest = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/dashboard");
};

const isAuthenticated = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};

const isAuthenticatedAndAdmin = (to, from, next) => {
  if (
    store.getters["auth/isAuthenticated"] &&
    store.getters["auth/role"] == "admin"
  ) {
    next();
    return;
  }
  store.getters["auth/isAuthenticated"]
    ? next("/errors/forbidden")
    : next("/login");
};

const routes = [
  {
    path: "/login",
    component: Login,
    name: "login",
    beforeEnter: isGuest,
    meta: { layout: "auth" },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    name: "forgot.password",
    beforeEnter: isGuest,
    meta: { layout: "auth" },
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    name: "reset.password",
    beforeEnter: isGuest,
    meta: { layout: "auth" },
  },
  {
    path: "/accounts/profile",
    component: Profile,
    name: "accounts.profile",
    beforeEnter: isAuthenticated,
  },
  {
    path: "/dashboard",
    component: Home,
    name: "dashboard",
    beforeEnter: isAuthenticated,
  },
  {
    path: "/customers",
    component: CustomerList,
    name: "customers.index",
    beforeEnter: isAuthenticatedAndAdmin,
  },
  {
    path: "/customers/create",
    component: CustomerCreate,
    name: "customers.create",
    beforeEnter: isAuthenticatedAndAdmin,
  },
  {
    path: "/customers/:slug",
    component: CustomerDetails,
    name: "customers.show",
    beforeEnter: isAuthenticatedAndAdmin,
  },
  {
    path: "/customers/:slug/edit",
    component: CustomerEdit,
    name: "customers.edit",
    beforeEnter: isAuthenticatedAndAdmin,
  },
  // {
  //   path: "/licenses",
  //   component: LicenseList,
  //   name: "licenses.index",
  //   beforeEnter: isAuthenticated,
  // },
  // {
  //   path: "/licenses/create",
  //   component: LicenseCreate,
  //   name: "licenses.create",
  //   beforeEnter: isAuthenticatedAndAdmin,
  // },
  {
    path: "/backups",
    component: BackupList,
    name: "backups.index",
    beforeEnter: isAuthenticated,
  },
  {
    path: "/errors/forbidden",
    component: Forbidden,
    name: "errors.forbidden",
    meta: { layout: "auth" },
  },
  { path: "*", redirect: "/dashboard" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.layout && to.meta.layout == "auth") {
    store.dispatch("app/setLayout", "auth");
  } else {
    store.dispatch("app/setLayout", "app");
  }

  next(true);
});

export default router;
