<template>
  <b-skeleton-table
    :columns="columns"
    :hide-header="hide_header"
    :rows="rows"
  ></b-skeleton-table>
</template>
  <script>
export default {
  props: {
    columns: {
      type: Number,
      default: 5,
    },
    hide_header: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  name: "Table-Skeleton",
};
</script>