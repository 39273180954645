<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/">
              <img src="@/assets/logo.svg" class="navbar-logo" alt="logo" />
            </router-link>
          </li>
          <li class="nav-item">
            <a
              href="javascript:;"
              class="sidebarCollapse text-white"
              data-placement="bottom"
              @click="toggleSideBar"
              role="button"
              v-html="getSvg('menu')"
            >
            </a>
          </li>
          <li class="nav-item">
            <portal-target name="breadcrumb"> </portal-target>
          </li>
        </ul>

        <div class="navbar-item flex-row ml-md-auto">
          <div class="dark-mode d-flex align-items-center">
            <a
              v-if="darkMode == 'light'"
              href="javascript:;"
              class="d-flex align-items-center"
              @click="toggleMode('dark')"
            >
              <div v-html="getSvg('sun')"></div>
              <span class="ml-2">Light</span>
            </a>
            <a
              v-if="darkMode == 'dark'"
              href="javascript:;"
              class="d-flex align-items-center"
              @click="toggleMode('light')"
            >
              <div v-html="getSvg('moon')"></div>
              <span class="ml-2">Dark</span>
            </a>
          </div>

          <b-dropdown
            toggle-tag="a"
            variant="icon-only"
            toggle-class="user nav-link"
            class="nav-item user-profile-dropdown"
            :right="true"
          >
            <template #button-content>
              <img :src="userLogo" alt="avatar" v-if="userLogo" />
              <b-avatar
                variant="light"
                :text="userName | short_hand"
                class="avatar-xl"
                v-else
              ></b-avatar>
            </template>

            <b-dropdown-item to="/accounts/profile">
              <span v-html="getSvg('user')"></span>
              Profile
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout">
              <span v-html="getSvg('log-out')"></span>
              Sign Out
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </header>
    </div>
    <!--  END NAVBAR  -->

    <!--  BEGIN TOPBAR  -->
    <div class="topbar-nav header navbar" role="banner">
      <nav class="topbar">
        <ul class="list-unstyled menu-categories" id="topAccordion">
          <li class="menu single-menu">
            <a
              href="javascript:;"
              data-toggle="collapse"
              aria-expanded="true"
              class="dropdown-toggle autodroprown"
            >
              <div class="">
                <i data-feather="home"></i>
                <span>{{ $t("dashboard") }}</span>
              </div>
              <i data-feather="chevron-down"></i>
            </a>
            <ul
              class="collapse submenu list-unstyled"
              id="dashboard"
              data-parent="#topAccordion"
            >
              <router-link tag="li" to="/dashboard"><a>Main</a></router-link>
            </ul>
          </li>
          <li class="menu single-menu" v-isAdmin>
            <a
              href="javascript:;"
              data-toggle="collapse"
              aria-expanded="true"
              class="dropdown-toggle autodroprown"
            >
              <div class="">
                <i data-feather="users"></i>
                <span>Customers</span>
              </div>
              <i data-feather="chevron-down"></i>
            </a>
            <ul
              class="collapse submenu list-unstyled"
              id="customers"
              data-parent="#topAccordion"
            >
              <router-link tag="li" :to="{ name: 'customers.create' }"
                ><a>Create</a></router-link
              >
              <router-link tag="li" :to="{ name: 'customers.index' }"
                ><a>List</a></router-link
              >
            </ul>
          </li>
          <li class="menu single-menu">
            <a
              href="javascript:;"
              data-toggle="collapse"
              aria-expanded="true"
              class="dropdown-toggle autodroprown"
            >
              <div class="">
                <i data-feather="layers"></i>
                <span>Licenses</span>
              </div>
              <i data-feather="chevron-down"></i>
            </a>
            <ul
              class="collapse submenu list-unstyled"
              id="licenses"
              data-parent="#topAccordion"
            >
              <router-link v-isAdmin tag="li" :to="{ name: 'licenses.create' }"
                ><a>Create</a></router-link
              >
              <router-link tag="li" :to="{ name: 'licenses.index' }"
                ><a>List</a></router-link
              >
            </ul>
          </li>
          <li class="menu single-menu">
            <a
              href="javascript:;"
              data-toggle="collapse"
              aria-expanded="true"
              class="dropdown-toggle autodroprown"
            >
              <div class="">
                <i data-feather="archive"></i>
                <span>Backups</span>
              </div>
              <i data-feather="chevron-down"></i>
            </a>
            <ul
              class="collapse submenu list-unstyled"
              id="backups"
              data-parent="#topAccordion"
            >
              <router-link tag="li" :to="{ name: 'backups.index' }"
                ><a>List</a></router-link
              >
            </ul>
          </li>
          <li class="menu single-menu">
            <a
              href="javascript:;"
              data-toggle="collapse"
              aria-expanded="true"
              class="dropdown-toggle autodroprown"
            >
              <div class="">
                <i data-feather="settings"></i>
                <span>Account Settings</span>
              </div>
              <i data-feather="chevron-down"></i>
            </a>
            <ul
              class="collapse submenu list-unstyled"
              id="accounts"
              data-parent="#topAccordion"
            >
              <router-link tag="li" to="/accounts/profile"
                ><a>Profile</a></router-link
              >
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <!--  END TOPBAR  -->
  </div>
</template>
<script>
import feather from "feather-icons";
export default {
  name: "Header",
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      selectedLang: null,
      countryList: this.$store.getters["app/countryList"],
    };
  },

  mounted() {
    feather.replace();
    this.selectedLang = this.$appSetting.toggleLanguage();
    this.toggleMode();
  },
  methods: {
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    toggleMode(mode) {
      this.$appSetting.toggleMode(mode);
    },
    changeLanguage(item) {
      this.selectedLang = item;
      this.$appSetting.toggleLanguage(item);
    },
    toggleSideBar() {
      this.$store.dispatch(
        "app/toggleSideBar",
        !this.$store.getters["app/isShowSidebar"]
      );
    },
    toggleSearch() {
      this.$store.dispatch(
        "app/toggleSearch",
        !this.$store.getters["app/isShowSearch"]
      );
    },
    logout() {
      this.$axios.post("auth/logout").then((response) => {
        this.$store.dispatch("auth/logout").then((store) => {
          this.$router.push({ name: "login" });
        });
      });
    },
  },
  computed: {
    userName() {
      return this.$store.getters["auth/userName"];
    },
    userLogo() {
      return this.$store.getters["auth/thumbnail"];
    },
    isShowSearch() {
      return this.$store.getters["app/isShowSearch"];
    },
    darkMode() {
      return this.$store.getters["app/darkMode"];
    },
  },
  filters: {
    short_hand(name) {
      // You can put your logic here...
      if (name) {
        let words = name.split(" ");
        let short_hand = words[0][0] + words[words.length - 1][0];
        return short_hand; // <-- The return value as Per logic
      }
    },
  },
};
</script>
