<template>
  <div class="layout-px-spacing">
    <div class="error404 text-center">
      <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
            <router-link to="/" class="ml-md-5">
              <img
                alt="image-404"
                src="@/assets/images/logo2.svg"
                class="theme-logo"
            /></router-link>
          </div>
        </div>
      </div> -->
      <div class="container-fluid error-content">
        <div class="">
          <h1 class="error-number">403</h1>
          <p class="mini-text">Ooops!</p>
          <p class="error-text mb-4 mt-1">
            You do not have the permission to view this page!
          </p>
          <b-button
            tag="a"
            :to="{ name: 'dashboard' }"
            variant="primary"
            class="mt-5"
            >Go Dashboard</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/sass/pages/error/style-400.scss";
export default {
  name: "Forbidden",
  metaInfo: { title: "Error 403" },
  mounted() {},
  methods: {},
};
</script>
