<template>
  <div class="form full-form auth-cover">
    <div class="form-container">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <h2 class="mb-5">Sign In to your account</h2>
              <b-form class="text-left" @submit.prevent="handleSubmit">
                <b-form-group
                  :invalid-feedback="invalidEmail"
                  :state="validateState('email')"
                >
                  <div id="username-field" class="field-wrapper input">
                    <i data-feather="user"></i>
                    <b-form-input
                      type="email"
                      v-model="form.email"
                      placeholder="Email"
                      trim
                      ref="email"
                    ></b-form-input>
                  </div>
                  <b-list-group class="list-unstyled" :flush="true">
                    <b-list-group-item
                      class="text-danger p-0 border-0"
                      v-for="error in errors.email"
                      :key="error"
                    >
                      <small class="invalid-feedback d-block">{{
                        error
                      }}</small>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <b-form-group
                  :invalid-feedback="invalidPassword"
                  :state="validateState('password')"
                >
                  <div id="password-field" class="field-wrapper input mb-2">
                    <i data-feather="lock"></i>
                    <b-input
                      :type="pwdType"
                      v-model="form.password"
                      placeholder="Password"
                      trim
                      ref="password"
                    ></b-input>
                  </div>
                  <b-list-group class="list-unstyled" :flush="true">
                    <b-list-group-item
                      class="text-danger p-0 border-0"
                      v-for="error in errors.password"
                      :key="error"
                    >
                      <small class="invalid-feedback d-block">{{
                        error
                      }}</small>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <div class="d-sm-flex justify-content-between">
                  <div
                    class="field-wrapper toggle-pass d-flex align-items-center"
                  >
                    <p class="d-inline-block">Show Password</p>
                    <b-checkbox
                      switch
                      class="switch s-primary"
                      @change="setPasswordType"
                    ></b-checkbox>
                  </div>
                  <div class="field-wrapper">
                    <b-button
                      type="submit"
                      :disabled="loading"
                      variant="primary"
                    >
                      <b-spinner
                        variant="light"
                        small
                        v-if="loading"
                      ></b-spinner>
                      <span v-else>Log In</span>
                    </b-button>
                  </div>
                </div>

                <div class="field-wrapper">
                  <router-link
                    :to="{
                      name: 'forgot.password',
                    }"
                    class="forgot-pass-link"
                    >Forgot Password?</router-link
                  >
                </div>

                <b-toast
                  id="error-message"
                  header-class="d-none"
                  body-class="toast-danger d-flex justify-content-between"
                  toaster="b-toaster-top-right"
                  auto-hide-delay="200"
                >
                  {{ errorMessage }}
                </b-toast>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div class="form-image">
        <div class="l-image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/sass/authentication/auth.scss";
import { required, email } from "vuelidate/lib/validators";
import feather from "feather-icons";

export default {
  name: "Login",
  metaInfo: { title: "Login" },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      pwdType: "password",
      form: {
        email: "",
        password: "",
      },
      errors: {},
      submitted: false,
      loading: false,
      errorMessage: "",
    };
  },
  mounted() {
    feather.replace();
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    focusOnError() {
      // 1. Loop the keys
      for (let key in Object.keys(this.$v.form)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.form)[key];
        // // 3. Remove special properties
        if (input.includes("$")) return false;

        // // 4. Check for errors
        if (this.$v.form[input].$error) {
          // 5. Focus the input with the error
          if (this.$refs[input]?.$el) {
            this.$refs[input].$el.focus();
          } else {
            this.$refs[input].focus();
          }

          // 6. Break out of the loop
          break;
        }
      }
    },
    setPasswordType() {
      if (this.pwdType == "password") {
        this.pwdType = "text";
      } else {
        this.pwdType = "password";
      }
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.login();
      } else {
        this.focusOnError();
      }
    },
    login() {
      this.loading = true;
      this.$axios
        .post("auth/login", this.form)
        .then((response) => {
          this.loading = false;
          this.submitted = true;
          const { data } = response.data;
          this.$store.dispatch("auth/saveAuthToken", data).then((store) => {
            this.$router.push({ name: "dashboard" });
            const toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              padding: "1em",
            });
            toast.fire({
              icon: "success",
              title: "Welcome back!",
              padding: "1em",
            });
          });
        })
        .catch(({ response }) => {
          this.loading = false;
          this.submitted = true;
          if (response) {
            if (response.data.errors) {
              this.errors = response.data.errors;
            } else {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          } else {
            this.errorMessage = "Network error";
            this.$bvToast.show("error-message");
          }
        });
    },
  },
  computed: {
    invalidEmail() {
      let message = "";
      if (!this.$v.form.email.email) {
        message = "Please enter a valid email!";
      } else if (!this.$v.form.email.required) {
        message = "Email is required!";
      }
      return message;
    },
    invalidPassword() {
      let message = "";
      if (!this.$v.form.password.required) {
        message = "Password is required!";
      }
      return message;
    },
  },
};
</script>
