<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
      <b-form id="general-info" class="section general-info">
        <div class="info">
          <b-skeleton type="button"></b-skeleton>
          <div class="row">
            <div class="col-lg-11 mx-auto">
              <div class="row">
                <div class="col-xl-2 col-lg-12 col-md-4">
                  <div class="upload mt-4 pr-md-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                </div>
                <div class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                  <div class="form">
                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group>
                          <b-skeleton type="input"></b-skeleton>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group>
                          <b-skeleton type="input"></b-skeleton>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group>
                          <b-skeleton type="input"></b-skeleton>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group>
                          <b-skeleton type="input"></b-skeleton>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group>
                          <b-skeleton type="input"></b-skeleton>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
      <b-form id="security" class="section contact">
        <div class="info">
          <b-skeleton type="button"></b-skeleton>
          <div class="row mt-5">
            <div class="col-md-11 mx-auto">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
      <b-form id="contact" class="section contact">
        <div class="info">
          <b-skeleton type="button"></b-skeleton>
          <div class="row mt-5">
            <div class="col-md-11 mx-auto">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <b-skeleton type="input"></b-skeleton>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "Customer-Edit-Skeleton",
};
</script>
    
    <style>
</style>