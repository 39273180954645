<template>
  <div :class="[layoutStyle, menuStyle]">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <component v-bind:is="layout"></component>
  </div>
</template>
<script>
// layouts
import appLayout from "./layouts/app-layout.vue";
import authLayout from "./layouts/auth-layout.vue";
import Loading from "vue-loading-overlay";
import "@/assets/sass/app.scss";

export default {
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: "%s | " + process.env.VUE_APP_NAME,
  },
  components: {
    app: appLayout,
    auth: authLayout,
    Loading,
  },
  computed: {
    layout() {
      return this.$store.getters["app/layout"];
    },
    menuStyle() {
      return this.$store.getters["app/menuStyle"];
    },
    layoutStyle() {
      return this.$store.getters["app/layoutStyle"];
    },
    isLoading() {
      return this.$store.getters["app/isLoading"];
    },
  },
  data() {
    return {
      fullPage: true,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
