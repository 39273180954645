
<template>
  <div class="layout-px-spacing dash_1">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Dashboard</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <b-toast
      id="error-message"
      header-class="d-none"
      body-class="toast-danger d-flex justify-content-between"
      toaster="b-toaster-top-right"
      auto-hide-delay="200"
    >
      {{ errorMessage }}
    </b-toast>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <HomeSkeleton />
      </template>
      <div class="row layout-top-spacing">
        <div
          class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-revenue">
            <div class="widget-heading">
              <h5>New Licenses Activations</h5>
            </div>

            <div class="widget-content">
              <apexchart
                v-if="activations_options"
                height="325"
                type="area"
                :options="activations_options"
                :series="activations_series"
              ></apexchart>
            </div>
          </div>
        </div>

        <div
          class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget">
            <div class="widget-heading">
              <h5>License Share</h5>
            </div>
            <div class="widget-content">
              <apexchart
                v-if="licenses_options"
                height="460"
                type="donut"
                :options="licenses_options"
                :series="licenses_series"
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div
            class="row widget-statistic row-cols-1"
            :class="{
              'row-cols-md-3 row-cols-lg-5': isAdmin,
              'row-cols-md-4 row-cols-lg-4': !isAdmin,
            }"
          >
            <div class="col layout-spacing" v-isAdmin>
              <div class="widget">
                <div class="widget-heading">
                  <div class="w-title">
                    <div class="w-icon icon-fill-secondary">
                      <div v-html="getSvg('users')"></div>
                    </div>
                    <div>
                      <p class="w-value">{{ summary.customers }}</p>
                      <h5>Customers</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col layout-spacing">
              <div class="widget">
                <div class="widget-heading">
                  <div class="w-title">
                    <div class="w-icon icon-fill-info">
                      <div v-html="getSvg('layers')"></div>
                    </div>
                    <div>
                      <p class="w-value">{{ summary.total_licenses }}</p>
                      <h5>Total Licenses</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col layout-spacing">
              <div class="widget">
                <div class="widget-heading">
                  <div class="w-title">
                    <div class="w-icon icon-fill-success">
                      <div v-html="getSvg('check')"></div>
                    </div>
                    <div>
                      <p class="w-value">{{ summary.licenses_installed }}</p>
                      <h5>Installed</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col layout-spacing">
              <div class="widget">
                <div class="widget-heading">
                  <div class="w-title">
                    <div class="w-icon icon-fill-warning">
                      <div v-html="getSvg('file-text')"></div>
                    </div>
                    <div>
                      <p class="w-value">{{ summary.licenses_remaining }}</p>
                      <h5>Remaining</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col layout-spacing">
              <div class="widget">
                <div class="widget-heading">
                  <div class="w-title">
                    <div class="w-icon icon-fill-danger">
                      <div v-html="getSvg('x')"></div>
                    </div>
                    <div>
                      <p class="w-value">{{ summary.licenses_expiring }}</p>
                      <h5>Expiring (this month)</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import "@/assets/sass/widgets/widgets.scss";
import feather from "feather-icons";
import HomeSkeleton from "./HomeSkeleton.vue";
export default {
  name: "Dashboard",
  metaInfo: { title: "Dashboard" },
  components: {
    HomeSkeleton,
  },
  data() {
    return {
      summary: {
        customers: 0,
        total_licenses: 0,
        licenses_installed: 0,
        licenses_remaining: 0,
        licenses_expiring: 0,
      },

      activations_series: [],
      activations_options: {},

      licenses_series: [],
      licenses_options: {},

      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/role"] == "admin";
    },
  },
  mounted() {
    feather.replace();
    this.getSummary();
  },
  methods: {
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    getSummary() {
      this.loading = true;
      this.$axios
        .get("dashboard")
        .then((response) => {
          this.loading = false;
          const { data } = response.data;
          this.setSummaryData(data.summary);
          this.setActivationsChart(data.license_activations);
          this.setLicensesChart(data.license_share);
        })
        .catch(({ response }) => {
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            this.loading = false;
            this.errorMessage = response.data.message;
            this.$bvToast.show("error-message");
          }
        });
    },
    setSummaryData(data) {
      this.summary.customers = data.customers ? data.customers : 0;
      this.summary.total_licenses = data.total_licenses;
      this.summary.licenses_installed = data.licenses_installed;
      this.summary.licenses_remaining = data.licenses_remaining;
      this.summary.licenses_expiring = data.licenses_expiring;
    },
    setActivationsChart(data) {
      const is_dark = this.$store.state.is_dark_mode;
      let labels = data.map((entry) => entry.month);
      let activations = data.map((entry) => entry.activations);

      this.activations_series = [
        {
          name: "Activations",
          data: activations,
        },
      ];
      this.activations_options = {
        chart: {
          fontFamily: "Nunito, sans-serif",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, curve: "smooth", width: 2, lineCap: "square" },
        dropShadow: {
          enabled: true,
          opacity: 0.2,
          blur: 10,
          left: -7,
          top: 22,
        },
        colors: is_dark ? ["#2196f3", "#e7515a"] : ["#1b55e2", "#e7515a"],
        markers: {
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 7,
              fillColor: "#000",
              strokeColor: "#000",
              size: 5,
            },
          ],
        },
        labels: labels,
        xaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          crosshairs: { show: true },
          labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
              fontSize: "12px",
              fontFamily: "Nunito, sans-serif",
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        grid: {
          borderColor: is_dark ? "#191e3a" : "#e0e6ed",
          strokeDashArray: 5,
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: false } },
          padding: { top: 0, right: 0, bottom: 0, left: 0 },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: 0,
          fontSize: "16px",
          fontFamily: "Nunito, sans-serif",
          markers: {
            width: 10,
            height: 10,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: { horizontal: 20, vertical: 5 },
        },
        tooltip: { theme: "dark", marker: { show: true }, x: { show: false } },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: is_dark ? 0.19 : 0.28,
            opacityTo: 0.05,
            stops: is_dark ? [100, 100] : [45, 100],
          },
        },
      };
    },
    setLicensesChart(data) {
      const is_dark = this.$store.state.is_dark_mode;
      this.licenses_series = [
        data.activated,
        data.not_activated,
        data.disabled,
      ];
      let option = {
        chart: {},
        dataLabels: { enabled: false },
        expandOnClick: is_dark ? false : true,
        stroke: { show: true, width: 25, colors: is_dark ? "#0e1726" : "#fff" },
        colors: is_dark
          ? ["#c38d1a", "#3fe29b", "#e7515a", "#3fe29b"]
          : ["#3fe29b", "#c38d1a", "#e7515a"],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "14px",
          markers: { width: 10, height: 10 },
          height: 50,
          offsetY: 20,
          itemMargin: { horizontal: 8, vertical: 0 },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "29px",
                  fontFamily: "Nunito, sans-serif",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "26px",
                  fontFamily: "Nunito, sans-serif",
                  color: is_dark ? "#bfc9d4" : undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  color: "#888ea8",
                  fontSize: "29px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: ["Activated", "Not Activated", "Disabled"],
      };

      if (is_dark) {
        option["states"] = {
          hover: { filter: { type: "none" } },
          active: { filter: { type: "none" } },
        };
      }

      this.licenses_options = option;
    },
  },
};
</script>
