<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="javascript:;">Account Settings</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Profile</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <div class="account-settings-container layout-top-spacing">
      <div class="account-content">
        <div
          class="scrollspy-example"
          data-spy="scroll"
          data-target="#account-settings-scroll"
          data-offset="-100"
        >
          <b-skeleton-wrapper :loading="formLoading && resetLoading">
            <template #loading>
              <ProfileSkeleton />
            </template>

            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <b-form id="general-info" class="section general-info">
                  <div class="info">
                    <h6 class="">Customer Information</h6>
                    <div class="row">
                      <div class="col-lg-11 mx-auto">
                        <div class="row">
                          <div class="col-xl-2 col-lg-12 col-md-4" v-isCustomer>
                            <div class="upload mt-4 pr-md-4">
                              <input
                                ref="fl_profile"
                                type="file"
                                class="d-none"
                                accept="image/*"
                                @change="changeFile"
                              />
                              <img
                                :src="selectedFile ? selectedFile : logo"
                                alt="profile"
                                class="profile-preview"
                                @click="$refs.fl_profile.click()"
                              />
                              <p class="mt-2">Upload Picture</p>
                              <b-list-group class="list-unstyled" :flush="true">
                                <b-list-group-item
                                  class="text-danger p-0 border-0"
                                  v-for="error in errors.logo"
                                  :key="error"
                                >
                                  <small class="invalid-feedback d-block">{{
                                    error
                                  }}</small>
                                </b-list-group-item>
                              </b-list-group>
                            </div>
                          </div>
                          <div
                            class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4"
                          >
                            <div class="form">
                              <div class="row">
                                <div class="col-sm-6">
                                  <b-form-group
                                    :invalid-feedback="invalidName"
                                    :state="validateState('name')"
                                  >
                                    <label for="fullName">Full Name</label>
                                    <b-form-input
                                      id="fullName"
                                      v-model="form.name"
                                      placeholder="Name"
                                      trim
                                      :state="validateState('name')"
                                      ref="name"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.name"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                                <div class="col-sm-6">
                                  <label class="dob-input">Email</label>
                                  <b-form-group :disabled="true">
                                    <b-form-input :value="email"></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6" v-isCustomer>
                                  <b-form-group>
                                    <label for="country">Country</label>
                                    <multiselect
                                      id="country"
                                      v-model="selectedCountry"
                                      :options="countries"
                                      :searchable="true"
                                      :preselect-first="false"
                                      @select="changeCountry"
                                      label="text"
                                      track-by="text"
                                    ></multiselect>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6" v-isCustomer>
                                  <b-form-group
                                    :invalid-feedback="invalidState"
                                    :state="validateState('state_id')"
                                  >
                                    <label for="state">State</label>
                                    <multiselect
                                      id="state"
                                      v-model="selectedState"
                                      :options="states"
                                      :searchable="true"
                                      :preselect-first="false"
                                      @select="changeState"
                                      label="text"
                                      track-by="text"
                                      ref="state_id"
                                    ></multiselect>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6" v-isCustomer>
                                  <b-form-group
                                    :invalid-feedback="invalidCity"
                                    :state="validateState('city_id')"
                                  >
                                    <label for="city">City</label>
                                    <multiselect
                                      id="city"
                                      v-model="selectedCity"
                                      :options="cities"
                                      :searchable="true"
                                      :preselect-first="false"
                                      label="text"
                                      track-by="text"
                                      @select="changeCity"
                                      ref="city_id"
                                    ></multiselect>
                                  </b-form-group>
                                  <b-list-group
                                    class="list-unstyled"
                                    :flush="true"
                                  >
                                    <b-list-group-item
                                      class="text-danger p-0 border-0"
                                      v-for="error in errors.city_id"
                                      :key="error"
                                    >
                                      <small class="invalid-feedback d-block">{{
                                        error
                                      }}</small>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                                <div class="col-md-6" v-isCustomer>
                                  <b-form-group
                                    label="API Token"
                                    description="Use this as a static token in external apps"
                                    label-for="api_token"
                                  >
                                    <b-input-group>
                                      <b-input
                                        :disabled="true"
                                        id="api_token"
                                        v-model="customer.api_token"
                                      ></b-input>
                                      <b-input-group-append>
                                        <b-button
                                          variant="primary"
                                          v-clipboard:copy="customer.api_token"
                                          v-html="getSvg('copy')"
                                          @click="
                                            $bvToast.show('clipboard-message')
                                          "
                                        ></b-button>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>

              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <b-form id="security" class="section contact">
                  <div class="info">
                    <h5 class="">Security</h5>
                    <div class="row">
                      <div class="col-md-11 mx-auto">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group
                              :invalid-feedback="invalidPassword"
                              :state="validateState('password')"
                            >
                              <label for="password">Password</label>
                              <b-form-input
                                type="password"
                                id="password"
                                placeholder="Enter new password"
                                v-model="form.password"
                                :state="validateState('password')"
                                ref="password"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group
                              :invalid-feedback="invalidConfirmationPassword"
                              :state="validateState('password_confirmation')"
                            >
                              <label for="password_confirmation"
                                >Confirmation Password</label
                              >
                              <b-form-input
                                type="password"
                                id="password_confirmation"
                                placeholder="Confirm your password"
                                v-model="form.password_confirmation"
                                :state="validateState('password_confirmation')"
                                ref="password_confirmation"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>

              <div
                class="col-xl-12 col-lg-12 col-md-12 layout-spacing"
                v-isCustomer
              >
                <b-form id="contact" class="section contact">
                  <div class="info">
                    <h5 class="">Contact</h5>
                    <div class="row">
                      <div class="col-md-11 mx-auto">
                        <div class="row">
                          <div class="col-md-6">
                            <b-form-group>
                              <label for="contact_person">Contact Person</label>
                              <b-form-input
                                id="contact_person"
                                placeholder="Enter contact person"
                                v-model="form.contact_person"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group>
                              <label for="phone_1">Phone 1</label>
                              <b-form-input
                                id="phone_1"
                                placeholder="Enter phone number 1"
                                v-model="form.phone_number_1"
                                v-mask="'## ##########'"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group>
                              <label for="phone_2">Phone 2</label>
                              <b-form-input
                                id="phone_2"
                                placeholder="Enter phone number 2"
                                v-model="form.phone_number_2"
                                v-mask="'## ##########'"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group>
                              <label for="subdomain">Sub Domain</label>
                              <b-form-input
                                id="subdomain"
                                placeholder="Enter sub domain"
                                v-model="form.subdomain"
                                trim
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>

      <div class="account-settings-footer">
        <div class="as-footer-container">
          <b-button
            variant="primary"
            @click="resetAll"
            :disabled="resetLoading || formLoading"
          >
            <b-spinner variant="light" small v-if="resetLoading"></b-spinner>
            <span v-else>Reset All</span>
          </b-button>
          <b-button
            :disabled="resetLoading || formLoading"
            variant="success"
            @click="handleSubmit"
          >
            <b-spinner variant="light" small v-if="formLoading"></b-spinner>
            <span v-else>Save Changes</span>
          </b-button>
          <b-toast
            id="success-message"
            header-class="d-none"
            body-class="toast-success d-flex justify-content-between"
            toaster="b-toaster-top-right"
            auto-hide-delay="200"
          >
            Changes Saved Successfully
          </b-toast>
          <b-toast
            id="clipboard-message"
            header-class="d-none"
            body-class="toast-success d-flex justify-content-between"
            toaster="b-toaster-top-right"
            auto-hide-delay="200"
          >
            Copied To Clipboard
          </b-toast>
          <b-toast
            id="error-message"
            header-class="d-none"
            body-class="toast-danger d-flex justify-content-between"
            toaster="b-toaster-top-right"
            auto-hide-delay="200"
          >
            {{ errorMessage }}
          </b-toast>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueMask from "v-mask";
import VueClipboard from "vue-clipboard2";
Vue.use(VueMask);
Vue.use(VueClipboard);
import "@/assets/sass/scrollspyNav.scss";
import "@/assets/sass/forms/custom-clipboard.scss";
import "@/assets/sass/users/account-setting.scss";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  required,
  requiredIf,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import ProfileSkeleton from "./ProfileSkeleton.vue";
import feather from "feather-icons";

export default {
  name: "Profile",
  metaInfo: { title: "Profile" },
  components: {
    Multiselect,
    ProfileSkeleton,
  },
  data() {
    return {
      form: {
        name: "",
        state_id: "",
        city_id: "",
        password: "",
        password_confirmation: "",
        contact_person: "",
        phone_number_1: "",
        phone_number_2: "",
        subdomain: "",
        logo: {},
      },
      selectedCountry: {},
      selectedState: {},
      selectedCity: {},
      email: "",
      role: "",
      customer: {},
      selectedFile: "",
      errors: {},
      countries: [],
      states: [],
      cities: [],
      submitted: false,
      formLoading: false,
      resetLoading: false,
      errorMessage: "",
      logo: "",
    };
  },
  validations: {
    form: {
      name: { required },
      state_id: {
        required: requiredIf(function (form) {
          if (this.isAdmin) {
            return false;
          } else {
            return Object.keys(this.selectedCountry).length !== 0;
          }
        }),
      },
      city_id: {
        required: requiredIf(function (form) {
          if (this.isAdmin) {
            return false;
          } else {
            return (
              Object.keys(this.selectedCountry).length !== 0 &&
              Object.keys(this.selectedState).length !== 0
            );
          }
        }),
      },
      password: { minLength: minLength(8) },
      password_confirmation: {
        required: requiredIf(function (form) {
          return form.password !== "";
        }),
        sameAsPassword: sameAs("password"),
      },
      contact_person: {},
      phone_number_1: {},
      phone_number_2: {},
      subdomain: {},
    },
  },
  mounted() {
    feather.replace();
    this.getUserDetails();
  },
  methods: {
    getSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    focusOnError() {
      // 1. Loop the keys
      for (let key in Object.keys(this.$v.form)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.form)[key];
        // // 3. Remove special properties
        if (input.includes("$")) return false;
        // // 4. Check for errors
        if (this.$v.form[input].$error) {
          // 5. Focus the input with the error
          if (this.$refs[input]?.$el) {
            this.$refs[input].$el.focus();
          } else {
            this.$refs[input].focus();
          }
          // 6. Break out of the loop
          break;
        }
      }
    },
    resetAll() {
      this.getUserDetails();
    },
    changeCountry(e) {
      this.getStates(e.value);
    },
    changeState(e) {
      this.form.state_id = e.value;
      this.getCities(e.value);
    },
    changeCity(e) {
      this.form.city_id = e.value;
    },
    getCountries() {
      this.formLoading = true;
      return this.$axios
        .get("countries")
        .then((response) => {
          this.formLoading = false;
          const { data } = response.data;
          this.countries = data.map((country) => ({
            value: country.country_id,
            text: country.name,
          }));
        })
        .catch(({ response }) => {
          this.formLoading = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            this.errorMessage = response.data.message;
            this.$bvToast.show("error-message");
          }
        });
    },
    getStates(country_id = "") {
      this.formLoading = true;
      let country = country_id
        ? country_id
        : this.selectedCountry
        ? this.selectedCountry.value
        : "";
      if (country) {
        return this.$axios
          .get(`countries/${country}}/states`)
          .then((response) => {
            this.formLoading = false;
            const { data } = response.data;
            this.states = data.map((state) => ({
              value: state.state_id,
              text: state.name,
            }));
            this.cities = [];
            this.selectedState = {};
            this.selectedCity = {};
            this.form.city_id = "";
            this.form.state_id = "";
          })
          .catch(({ response }) => {
            this.formLoading = false;
            let errors = [401, 403];
            if (!errors.includes(response.status)) {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          });
      }
    },
    getCities(state_id = "") {
      this.formLoading = true;
      let state = state_id
        ? state_id
        : this.selectedState
        ? this.selectedState.value
        : "";
      if (state) {
        return this.$axios
          .get(`states/${state}}/cities`)
          .then((response) => {
            this.formLoading = false;
            const { data } = response.data;
            this.cities = data.map((city) => ({
              value: city.city_id,
              text: city.name,
            }));
            this.form.city_id = "";
            this.selectedCity = {};
          })
          .catch(({ response }) => {
            this.formLoading = false;
            let errors = [401, 403];
            if (!errors.includes(response.status)) {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          });
      }
    },
    getUserDetails() {
      this.formLoading = this.resetLoading = true;
      return this.$axios
        .get("auth/me")
        .then((response) => {
          const { data } = response.data;
          this.email = data.email;
          if (this.isAdmin) {
            this.form.name = data.name;
            this.formLoading = this.resetLoading = false;
          } else {
            this.form.name = data.customer.company_name;
            this.customer = data.customer;
            this.form.contact_person = this.customer.contact_person;
            this.form.phone_number_1 = this.customer.phone_number_1;
            this.form.phone_number_2 = this.customer.phone_number_2;
            this.form.subdomain = this.customer.subdomain;
            this.logo = this.customer.logo
              ? this.customer.logo.url
              : require("@/assets/images/user-avtar.svg");

            this.getCountries().then(() => {
              if (data.customer.country) {
                this.selectedCountry = this.countries.find(
                  (country) => country.value == data.customer.country.country_id
                );
                this.getStates(data.customer.country.country_id).then(() => {
                  if (data.customer.state) {
                    this.selectedState = this.states.find(
                      (state) => state.value == data.customer.state.state_id
                    );
                    this.getCities(data.customer.state.state_id).then(() => {
                      if (data.customer.city) {
                        this.selectedCity = this.cities.find(
                          (city) => city.value == data.customer.city.city_id
                        );
                        this.form.state_id = data.customer.state?.state_id;
                        this.form.city_id = data.customer.city?.city_id;
                      }
                      this.formLoading = this.resetLoading = false;
                    });
                  } else {
                    this.formLoading = this.resetLoading = false;
                  }
                });
              } else {
                this.formLoading = this.resetLoading = false;
              }
            });
          }
        })
        .catch(({ response }) => {
          this.formLoading = this.resetLoading = false;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            this.errorMessage = response.data.message;
            this.$bvToast.show("error-message");
          }
        });
    },
    changeFile(event) {
      this.form.logo = event.target.files[0];
      this.selectedFile = URL.createObjectURL(event.target.files[0]);
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.updateProfile();
      } else {
        this.focusOnError();
      }
    },
    updateProfile() {
      this.errors = {};
      this.formLoading = true;
      let update = {};
      if (this.isAdmin) {
        update = {
          name: this.form.name,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        };
      } else {
        update = this.form;
      }

      const getFormData = Object.entries(update).reduce((fd, [key, val]) => {
        if (Array.isArray(val)) {
          val.forEach((v) => fd.append(key, v ? v : ""));
        } else {
          fd.append(key, val ? val : "");
        }
        return fd;
      }, new FormData());
      getFormData.append("_method", "PATCH");
      if (this.selectedFile == "") {
        getFormData.delete("logo");
      }

      this.$axios
        .post("auth/profile", getFormData)
        .then((response) => {
          this.formLoading = false;
          this.submitted = true;
          this.form.password = this.form.password_confirmation = "";
          this.$store
            .dispatch("auth/updateUser", response.data.data)
            .then(() => this.$bvToast.show("success-message"));
        })
        .catch(({ response }) => {
          this.formLoading = false;
          this.submitted = true;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            if (response.data.errors) {
              this.errors = response.data.errors;
            } else {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          }
        });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/role"] == "admin";
    },
    invalidName() {
      let message = "";
      if (!this.$v.form.name.required) {
        message = "Name is required!";
      }
      return message;
    },
    invalidPassword() {
      let message = "";
      if (!this.$v.form.password.minLength) {
        message = "Password min length is 8!";
      }
      return message;
    },
    invalidConfirmationPassword() {
      let message = "";
      if (!this.$v.form.password_confirmation.required) {
        message = "Confirmation password is required!";
      } else if (!this.$v.form.password_confirmation.sameAsPassword) {
        message = "Confirmation password doesn't match!";
      }
      return message;
    },
    invalidCity() {
      let message = "";
      if (!this.$v.form.city_id.required) {
        message = "City is required!";
      }
      return message;
    },
    invalidState() {
      let message = "";
      if (
        Object.keys(this.selectedCountry).length !== 0 &&
        Object.keys(this.selectedState).length == 0
      ) {
        message = "State is required!";
      }
      return message;
    },
  },
};
</script>
