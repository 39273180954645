<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
      <div class="shadow-bottom"></div>

      <perfect-scrollbar
        class="list-unstyled menu-categories"
        tag="ul"
        :options="{
          wheelSpeed: 0.5,
          swipeEasing: !0,
          minScrollbarLength: 40,
          maxScrollbarLength: 300,
          suppressScrollX: true,
        }"
      >
        <ul class="list-unstyled">
          <router-link
            tag="li"
            :to="{ name: 'dashboard' }"
            class="menu"
            @click.native="toggleMobileMenu"
          >
            <a class="dropdown-toggle">
              <div class="">
                <div class="icon-container">
                  <i data-feather="home"></i
                  ><span class="icon-name">{{ $t("dashboard") }}</span>
                </div>
              </div>
            </a>
          </router-link>

        

          <li class="menu" v-isAdmin>
            <a
              href="#customers"
              v-b-toggle
              class="dropdown-toggle"
              :to="{ name: 'customers.index' }"
            >
              <div class="">
                <div class="icon-container">
                  <i data-feather="users"></i
                  ><span class="icon-name">Customers</span>
                </div>
              </div>
              <div>
                <div class="icon-container">
                  <i data-feather="chevron-right"></i>
                </div>
              </div>
            </a>
            <b-collapse id="customers" accordion="menu">
              <ul class="collapse submenu list-unstyled show">
                <router-link
                  tag="li"
                  :to="{ name: 'customers.create' }"
                  @click.native="toggleMobileMenu"
                  ><a>New Customer</a></router-link
                >
                <router-link
                  tag="li"
                  :to="{ name: 'customers.index' }"
                  @click.native="toggleMobileMenu"
                  ><a>All Customers</a></router-link
                >
              </ul>
            </b-collapse>
          </li>

          
          <router-link
            tag="li"
            :to="{ name: 'backups.index' }"
            class="menu"
          >
            <a class="dropdown-toggle">
              <div class="">
                <div class="icon-container">
                  <i data-feather="archive"></i
                  ><span class="icon-name">{{ $t("Backups") }}</span>
                </div>
              </div>
            </a>
          </router-link>

          

          <!-- <li class="menu">
            <a
              href="#licenses"
              v-b-toggle
              class="dropdown-toggle"
              @click.prevent
            >
              <div class="">
                <div class="icon-container">
                  <i data-feather="layers"></i
                  ><span class="icon-name">Licenses</span>
                </div>
              </div>
              <div>
                <div class="icon-container">
                  <i data-feather="chevron-right"></i>
                </div>
              </div>
            </a>
            <b-collapse id="licenses" accordion="menu">
              <ul class="collapse submenu list-unstyled show">
                <router-link
                  v-isAdmin
                  tag="li"
                  :to="{ name: 'licenses.create' }"
                  @click.native="toggleMobileMenu"
                  ><a>Create</a></router-link
                >
                <router-link
                  tag="li"
                  :to="{ name: 'licenses.index' }"
                  @click.native="toggleMobileMenu"
                  ><a>List</a></router-link
                >
              </ul>
            </b-collapse>
          </li> -->

          

          <!-- <li class="menu">
            <a
              href="#backups"
              v-b-toggle
              class="dropdown-toggle"
              @click.prevent
            >
              <div class="">
                <div class="icon-container">
                  <i data-feather="archive"></i
                  ><span class="icon-name">Backups</span>
                </div>
              </div>
              <div>
                <div class="icon-container">
                  <i data-feather="chevron-right"></i>
                </div>
              </div>
            </a>
            <b-collapse id="backups" accordion="menu">
              <ul class="collapse submenu list-unstyled show">
                <router-link
                  tag="li"
                  :to="{ name: 'backups.index' }"
                  @click.native="toggleMobileMenu"
                  ><a>List</a></router-link
                >
              </ul>
            </b-collapse>
          </li> -->

          <li class="menu">
            <a
              href="#accounts"
              v-b-toggle
              class="dropdown-toggle"
              @click.prevent
            >
              <div class="">
                <div class="icon-container">
                  <i data-feather="settings"></i
                  ><span class="icon-name">Account Settings</span>
                </div>
              </div>
              <div>
                <div class="icon-container">
                  <i data-feather="chevron-right"></i>
                </div>
              </div>
            </a>
            <b-collapse id="accounts" accordion="menu">
              <ul class="collapse submenu list-unstyled show">
                <router-link
                  tag="li"
                  :to="{ name: 'accounts.profile' }"
                  @click.native="toggleMobileMenu"
                  ><a>Profile</a></router-link
                >
              </ul>
            </b-collapse>
          </li>
        </ul>
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return { menu_collapse: "dashboard" };
  },
  watch: {
    $route(to) {
      const selector = document.querySelector(
        '#sidebar a[href="' + to.path + '"]'
      );
      if (selector) {
        const ul = selector.closest("ul.collapse");
        if (!ul) {
          const ele = document.querySelector(".dropdown-toggle.not-collapsed");
          if (ele) {
            ele.click();
          }
        }
      }
    },
  },

  mounted() {
    feather.replace();
    // default menu selection on refresh
    const selector = document.querySelector(
      '#sidebar a[href="' + window.location.pathname + '"]'
    );
    if (selector) {
      const ul = selector.closest("ul.collapse");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".dropdown-toggle");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      } else {
        selector.click();
      }
    }
  },

  methods: {
    toggleMobileMenu() {
      if (window.innerWidth < 991) {
        this.$store.dispatch(
          "app/toggleSideBar",
          !this.$store.getters["app/isShowSidebar"]
        );
      }
    },
  },
};
</script>
