export default {
  isLoading(state) {
    return state.isLoading;
  },
  layout(state) {
    return state.layout;
  },
  countryList(state) {
    return state.countryList;
  },
  locale(state) {
    return state.locale;
  },
  menuStyle(state) {
    return state.menu_style;
  },
  layoutStyle(state) {
    return state.layout_style;
  },
  isShowSidebar(state) {
    return state.is_show_sidebar;
  },
  isShowSearch(state) {
    return state.is_show_search;
  },
  isDarkMode(state) {
    return state.is_dark_mode;
  },
  darkMode(state) {
    return state.dark_mode;
  },
};
