<template>
  <div class="form full-form auth-cover">
    <div class="form-container">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <h1 class="">Password Recovery</h1>
              <p class="signup-link">Change your password</p>
              <b-form class="text-left" @submit.prevent="handleSubmit">
                <div class="form">
                  <b-form-group
                    :invalid-feedback="invalidEmail"
                    :state="validateState('email')"
                  >
                    <div id="email-field" class="field-wrapper input">
                      <i data-feather="at-sign"></i>
                      <b-input
                        type="email"
                        v-model="form.email"
                        placeholder="Email"
                        trim
                        ref="email"
                      ></b-input>
                    </div>
                    <b-list-group class="list-unstyled" :flush="true">
                      <b-list-group-item
                        class="text-danger p-0 border-0"
                        v-for="error in errors.email"
                        :key="error"
                      >
                        <small class="invalid-feedback d-block">{{
                          error
                        }}</small>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>

                  <b-form-group
                    :invalid-feedback="invalidPassword"
                    :state="validateState('password')"
                  >
                    <div id="email-field" class="field-wrapper input">
                      <i data-feather="key"></i>
                      <b-input
                        type="password"
                        v-model="form.password"
                        placeholder="Password"
                        trim
                        ref="password"
                      ></b-input>
                    </div>
                    <b-list-group class="list-unstyled" :flush="true">
                      <b-list-group-item
                        class="text-danger p-0 border-0"
                        v-for="error in errors.password"
                        :key="error"
                      >
                        <small class="invalid-feedback d-block">{{
                          error
                        }}</small>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>

                  <b-form-group
                    :invalid-feedback="invalidConfirmationPassword"
                    :state="validateState('password_confirmation')"
                  >
                    <div id="email-field" class="field-wrapper input">
                      <i data-feather="key"></i>
                      <b-input
                        type="password"
                        v-model="form.password_confirmation"
                        placeholder="Confirmation Password"
                        trim
                        ref="password_confirmation"
                      ></b-input>
                    </div>
                    <b-list-group class="list-unstyled" :flush="true">
                      <b-list-group-item
                        class="text-danger p-0 border-0"
                        v-for="error in errors.password_confirmation"
                        :key="error"
                      >
                        <small class="invalid-feedback d-block">{{
                          error
                        }}</small>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>

                  <div class="d-sm-flex justify-content-between">
                    <div class="field-wrapper">
                      <b-button type="submit" variant="primary">
                        <b-spinner
                          variant="light"
                          small
                          v-if="loading"
                        ></b-spinner>
                        <span v-else>Reset</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div class="form-image">
        <div class="l-image"></div>
      </div>
    </div>
    <b-toast
      id="error-message"
      header-class="d-none"
      body-class="toast-danger d-flex justify-content-between"
      toaster="b-toaster-top-right"
      auto-hide-delay="200"
    >
      {{ errorMessage }}
    </b-toast>
  </div>
</template>

<script>
import "@/assets/sass/authentication/auth.scss";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import feather from "feather-icons";

export default {
  name: "Reset-Password",
  metaInfo: { title: "Reset Password" },
  data() {
    return {
      form: {
        email: "",
        token: this.$route.params.token,
        password: "",
        password_confirmation: "",
      },
      errors: {},
      loading: false,
      submitted: false,
      errorMessage: "",
    };
  },
  validations: {
    form: {
      email: { required, email },
      token: { required },
      password: { required, minLength: minLength(8) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  mounted() {
    feather.replace();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    focusOnError() {
      // 1. Loop the keys
      for (let key in Object.keys(this.$v.form)) {
        // 2. Extract the input
        const input = Object.keys(this.$v.form)[key];
        // // 3. Remove special properties
        if (input.includes("$")) return false;
        // // 4. Check for errors
        if (this.$v.form[input].$error) {
          // 5. Focus the input with the error
          if (this.$refs[input]?.$el) {
            this.$refs[input].$el.focus();
          } else {
            this.$refs[input].focus();
          }
          // 6. Break out of the loop
          break;
        }
      }
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.resetPassword();
      } else {
        this.focusOnError();
      }
    },
    resetPassword() {
      this.errors = {};
      this.loading = true;

      const getFormData = Object.entries(this.form).reduce((fd, [key, val]) => {
        if (Array.isArray(val)) {
          val.forEach((v) => fd.append(key, v ? v : ""));
        } else {
          fd.append(key, val ? val : "");
        }
        return fd;
      }, new FormData());

      this.$axios
        .post("/reset-password", getFormData)
        .then((response) => {
          this.loading = false;
          this.submitted = true;
          this.$router.push({ name: "login" });
        })
        .catch(({ response }) => {
          this.loading = false;
          this.submitted = true;
          let errors = [401, 403];
          if (!errors.includes(response.status)) {
            if (response.data.errors) {
              this.errors = response.data.errors;
            } else {
              this.errorMessage = response.data.message;
              this.$bvToast.show("error-message");
            }
          }
        });
    },
  },
  computed: {
    invalidEmail() {
      let message = "";
      if (!this.$v.form.email.email) {
        message = "Please enter a valid email!";
      } else if (!this.$v.form.email.required) {
        message = "Email is required!";
      }
      return message;
    },
    invalidPassword() {
      let message = "";
      if (!this.$v.form.password.required) {
        message = "Password is required!";
      } else if (!this.$v.form.password.minLength) {
        message = "Password min length is 8!";
      }
      return message;
    },
    invalidConfirmationPassword() {
      let message = "";
      if (!this.$v.form.password_confirmation.required) {
        message = "Confirmation password is required!";
      } else if (!this.$v.form.password_confirmation.sameAsPassword) {
        message = "Confirmation password doesn't match!";
      }
      return message;
    },
  },
};
</script>
